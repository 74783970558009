import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Navbar, Nav, NavItem, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "../stylesheets/NavBar.css";

export default class AutoAuthNavBar extends Component {

  handleLogout = async event => {
    await Auth.signOut();
    this.props.userHasAuthenticated(false);
  }

  handleShopChange(event) {
    let newShop = event.target.value;
    this.setState({
      currentShop: newShop
    })
    if (this.props.handleShopChange) {
      this.props.handleShopChange(newShop);
    }
  }

  render() {

    const windowWidth = window.innerWidth;
    let emailVerified = this.props.emailVerified;

    return (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to="/">
              <img className="brand-image" alt="AutoAuth" src='img/AutoAuth-transparent-TM.png' />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className="navbar logged-in">
          <div>
            <ul className="nav navbar-nav">
              <li>
                <NavLink className={(this.props.pathname === "/home")?"nav-item selected":"nav-item"} to="/home">
                  Home
                </NavLink>
              </li>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <li>
                <NavLink className={(this.props.pathname === "/userProfile")?"nav-item selected":"nav-item"} to="/userProfile">
                  User Profile
                </NavLink>
              </li>
              { this.props.user && (this.props.user.userType === 'ADMIN' || this.props.user.userType === 'MANAGER') ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && (this.props.user.userType === 'ADMIN' || this.props.user.userType === 'MANAGER') ?
                (emailVerified ?
                  <li>
                    <NavLink className={(this.props.pathname === "/manageGroups")?"nav-item selected":"nav-item"} to="/manageGroups">
                      Manage Groups
                    </NavLink>
                  </li>
                  :
                    <NavItem className="nav-item disabled">Manage Groups</NavItem>
                  )
              : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                (emailVerified ?
                  <li>
                    <NavLink className={(this.props.pathname === "/manageUsers")?"nav-item selected":"nav-item"} to="/manageUsers">
                      Manage Users
                    </NavLink>
                  </li>
                :
                  <NavItem className="nav-item disabled">Manage Users</NavItem>
                )
               : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              : ''}
              { this.props.user && this.props.user.userType === 'ADMIN' ?
                (emailVerified ?
                  <li>
                    <NavLink className={(this.props.pathname === "/manageTools")?"nav-item selected":"nav-item"} to="/manageTools">
                      Manage Tools
                    </NavLink>
                  </li>
                :
                  <NavItem className="nav-item disabled">Manage Tools</NavItem>
                )
               : ''}

            </ul>
            <Nav pullRight>
              <NavItem className="header-text">{this.props.name}</NavItem>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <NavDropdown className={((this.props.pathname === "/#/FAQ" || this.props.pathname === "/#/Support" || this.props.pathname === "/SystemInfo") && windowWidth > 440)?"nav-item header-help selected":"nav-item header-help"} title={windowWidth > 768 ? <Icon title="Get Support" name="help circle" /> : "Support"} id="support-dropdown">
                <li>
                  <NavLink to="/SystemInfo">System Info</NavLink>
                </li>
              </NavDropdown>
              <NavItem className="header-text navbar-spacer"><span className="header-text">|</span></NavItem>
              <NavItem onClick={this.handleLogout}>Logout</NavItem>
            </Nav>
          </div>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
