export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://6a2tcim386.execute-api.us-west-2.amazonaws.com/dev",
    APIURL: "https://15qwojae19.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_7t1Q2q2oV",
    APP_CLIENT_ID: "7m7a4t5v6un9ljiq0mj7s95a66",
    IDENTITY_POOL_ID: "us-west-2:38b0bfd6-a195-4091-a8d8-9da43fd249eb"
  }
};
