import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { usernameValidate, fieldChangeCase } from "../libs/utils";
import "./Signup.css";

export default class ResendConfirmCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      newUser: null
    };
  }
  
  validateForm() {
    return usernameValidate(this.state.username);
  }

  handleChange = event => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, "lower");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }
  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.resendSignUp(this.state.username);
    } catch (e) {
      let errorMSg = e.message;
      if (errorMSg === 'Username/client id combination not found.') {
        // If username not found, do not indicate to the user
        this.setState({
          newUser: true
        });
      } else {
        alert(errorMSg);
      }
    }

    this.setState({ isLoading: false });
  }

  renderConfirmationForm() {
    return (
      <div>
        <div className="alignCenter">Account Validation link has been sent to the email address associated with: <span className="text-bold spacer10"> {this.state.username}</span></div>
        <div className="alignCenter">After validating account you can log in by clicking on the Login button on the top right of the page</div>
      </div>
    );
  }

  renderForm() {
    return (
      <form className="white-container" onSubmit={this.handleSubmit}>
        <FormGroup>
          <h2 className="text-center">Resend Confirm Link</h2>
        </FormGroup>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel className={this.state.usernameFieldActivate || this.state.username.length > 0 ? "float-label field-active" : "float-label"}>Username</ControlLabel>
          <FormControl
            autoFocus
            className={this.state.username.length > 0 && !usernameValidate(this.state.username)?'error':''}
            maxLength="50"
            type="text"
            value={this.state.username}
            onChange={this.handleChange}
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
      />
          { this.state.username.length > 0 && !usernameValidate(this.state.username) ?
            <HelpBlock className="error">Username must be at least 8 alphanumeric characters starting with a letter</HelpBlock>
            : ''
          }
        </FormGroup>
        <LoaderButton
          className="red-button"
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Resend Confirm Link"
          loadingText="Sending Link…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
