import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  Modal
} from "react-bootstrap";
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import AlertModal from "../components/AlertModal";
import { navbarSetup,
         resetUserData,
         nameValidate,
         isValidEmail } from "../libs/utils";
import {
         Button,
       } from 'react-bootstrap';
import {
  getRoles,
  updateUser,
  getUserGroups,
  getGroups,
} from "../libs/db-lib";
import moment from 'moment';
import LoaderButton from "../components/LoaderButton";
import RolePicker from "../components/RolePicker";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class EditUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      userName: '',
      userType: '',
      showDeleteModal: false,
      showUpdatedModal: false,
      editUser: props.location.state.editUser,
      email: "",
      firstName: "",
      lastName: "",
      rolesToDelete: [],
      rolesToAdd: [],
      revertIsLoading: false,
      isLoading: false
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let editUser = this.props.location.state.editUser;

      let roles = await getRoles();
      let results = await getUserGroups(this.state.editUser.userID);
      let userGroups = results.groupList;

      // Put the user details into the state
      this.setState({
        roles: roles ? roles : [],
        firstName: this.state.editUser.firstName ? this.state.editUser.firstName : '',
        lastName: this.state.editUser.lastName ? this.state.editUser.lastName : '',
        email: this.state.editUser.email ? this.state.editUser.email : '',
        emailVerified: this.state.editUser.emailVerified,
        userName: this.state.editUser.userName ? this.state.editUser.userName : '',
        userType: this.state.editUser.userType ? this.state.editUser.userType : '',
        userRole: this.state.editUser.userRoleList && this.state.editUser.userRoleList.length > 0 ? this.state.editUser.userRoleList[0] : '',
        userState: this.state.editUser.userState ? this.state.editUser.userState : '',
        editUser: editUser,
        userGroups: userGroups ? userGroups : [],
        authProvider: this.state.editUser.authProvider,
      });

    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
  }

  validateForm() {

    let namesValid = nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);

    return (
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      namesValid &&
      emailValid &&
      (this.state.firstName !== this.state.editUser.firstName ||
       this.state.lastName !== this.state.editUser.lastName ||
       this.state.email !== this.state.editUser.email ||
       this.state.userType !== this.state.editUser.userType ||
       (this.state.userRole !== this.state.editUser.userRoleList[0] &&
        !(this.state.userRole === '' && this.state.editUser.userRoleList.length === 0))
      )
    );
  }

  handleBreadcrumbClick = (newPage, event) => {
    if (this.validateForm()) {
      this.setState({
        showConfirmNavModal: true,
        newPage: newPage
      });
    } else {
      this.props.history.push({pathname: newPage});
    }
  }

  handleRoleChange(newRole) {
    this.setState({ userRole: newRole });
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleNavCancel = () => {
    this.setState({
      showConfirmNavModal: false,
      newPage: '' });
  }

  handleNavConfirm = (newPage) => {
    this.props.history.push({pathname: newPage});
  }

  handleHideUpdatedModal = () => {
    this.setState({ showUpdatedModal: false });
  }

  handleRevert = async event => {
    this.setState({ revertIsLoading: true });

    this.setState({
      firstName: this.state.editUser.firstName,
      lastName: this.state.editUser.lastName,
      email: this.state.editUser.email,
      groupName: this.state.editUser.groupName,
      userType: this.state.editUser.userType,
      userRole: this.state.editUser.userRoleList[0],
      revertIsLoading: false
    });
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const state = this.state;

    if ((state.firstName.length > 0 &&
         state.firstName !== state.editUser.firstName) ||
        (state.lastName.length > 0 &&
         state.lastName !== state.editUser.lastName) ||
        (state.userRole.length > 0 &&
         state.userRole !== state.editUser.userRole) ||
        (state.userType.length > 0 &&
         state.userType !== state.editUser.userType) ||
        (state.email.length > 0 &&
         state.email !== state.editUser.email)) {
      let result = await updateUser(
              state.editUser.userID,
              state.authProvider,
              state.firstName,
              state.lastName,
              state.email,
              state.userType !== state.editUser.userType ? state.userType : null,
              state.userRole !== state.editUser.userRoleList[0] ? state.userRole : null);
      if (result && result.error) {
        this.setState({
          alertMessage: result.error,
          showModal: true,
          isLoading: false
        });
      } else {
        let user = this.state.editUser;
        user.lastName = this.state.lastName;
        user.firstName = this.state.firstName;
        user.email = this.state.email;
        user.userRoleList[0] = this.state.userRole;
        user.userType= this.state.userType;

        this.setState({
          editUser: user,
          showUpdatedModal: true,
          isLoading: false
        });
      }
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    const columnDefs = [];
    columnDefs.push(
      { Header: "Name",
        accessor: "name",
        minWidth: 200 }
    );
    columnDefs.push(
      { Header: "Validity Criteria",
        accessor: "validityCriteria",
        maxWidth: 150 }
    );
    columnDefs.push(
      { Header: "Validity Time",
        id: "validityParams",
        accessor: (g) => {
          if (g.validityCriteria === "TIME") {
            const start = moment(new Date(g.validityParams.startDate));
            const end = moment(new Date(g.validityParams.endDate));

            return (
              <div>
                <span className="text-bold">{start.format('YYYY-MM-DD HH:mm:ss')}</span>
                <span> to </span>
                <span className="text-bold">{end.format('YYYY-MM-DD HH:mm:ss') + ' UTC'}</span>
              </div>
            );
         } else {
            return "N/A";
          }
        },
        minWidth: 330 })
    if (this.state.windowWidth > 500) {
      columnDefs.push(
        { Header: "State",
          accessor: "state",
          className: "text-center",
          minWidth: 50 }
      );
    }

    const groupColDefs = [];

    groupColDefs.push({ Header: "Name",
                      id: "name",
                      accessor: "name",
                      minWidth: (this.state.windowWidth > 505)?250:120 }
    );
    groupColDefs.push({ Header: "State",
                      accessor: "state",
                      className: "text-center",
                      minWidth: (this.state.windowWidth > 505)?120:100,
                      maxWidth: (this.state.windowWidth > 505)?120:100 }
    );

    groupColDefs.push({ Header: "Validity Criteria",
                        accessor: "validityCriteria",
                        maxWidth: 150 }
    );
    groupColDefs.push({ Header: "Validity Time",
                        id: "validityParams",
                        accessor: (g) => {
                          if (g.validityCriteria === "TIME") {
                            const start = moment(new Date(g.validityParams.startDate));
                            const end = moment(new Date(g.validityParams.endDate));

                            return (
                              <div>
                                <span className="text-bold">{start.format('YYYY-MM-DD HH:mm:ss')}</span>
                                <span> to </span>
                                <span className="text-bold">{end.format('YYYY-MM-DD HH:mm:ss') + ' UTC'}</span>
                              </div>
                            );
                         } else {
                            return "N/A";
                          }
                        },
                        className: "text-center",
                        minWidth: 330 }
    );

    let groups = this.state && this.state.userGroups && Array.isArray(this.state.userGroups) ?
      this.state.userGroups.map((group) => {
        return {
          groupID: group.groupID,
          name: group.groupName,
          role: group.activeRoleName,
          state: group.groupState,
          validityCriteria: group.validityCriteria,
          validityParams: group.validityParams
        }
      })
      : [];

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname}  handleClick={this.handleBreadcrumbClick.bind(this)}/>
        <div className="panel-frame">
          { this.state.roles ?
          <div>
            <div className="row">
              <form className="white-container" onSubmit={this.handleSubmit}>
                <div className="col-sm-6">
                  { this.state.authProvider === "COGNITO" ?
                  <div className="row">
                    <FormGroup controlId="firstName" bsSize="large" className="col-md-6 col-sm-6 col-xs-12">
                      <ControlLabel className="float-label field-active">First Name</ControlLabel>
                      <FormControl
                        autoFocus
                        maxLength="50"
                        className={!nameValidate(this.state.firstName)?'error':''}
                        placeholder="First Name"
                        type="text"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      { !nameValidate(this.state.firstName) ?
                        <HelpBlock className="error">First name cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="lastName" bsSize="large" className="col-md-6 col-sm-6 col-xs-12">
                      <ControlLabel className="float-label field-active">Last Name:</ControlLabel>
                      <FormControl
                        autoFocus
                        maxLength="50"
                        className={!nameValidate(this.state.lastName)?'error':''}
                        type="text"
                        placeholder="Last Name"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      { !nameValidate(this.state.lastName) ?
                        <HelpBlock className="error">Last name cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                  </div>
                  :
                  <div className="row">
                    <ControlLabel className="col-md-3 col-sm-3 right-align">Full Name:</ControlLabel>
                    <ControlLabel className="col-md-3 col-sm-4 left-align margin-bottom-15">{this.state.firstName + ' ' + this.state.lastName}</ControlLabel>
                  </div>
                  }

                  { this.state.authProvider === "COGNITO" ?
                  <div className="row">
                    <ControlLabel className="col-md-3 col-sm-3 right-align">Username/FID:</ControlLabel>
                    <ControlLabel className="col-md-3 col-sm-4 left-align">{this.state.userName}</ControlLabel>
                    <FormGroup controlId="email" bsSize="large" className="col-md-6 col-sm-6 col-xs-12">
                      <ControlLabel className="float-label field-active">Email Address:</ControlLabel>
                      <FormControl
                        autoFocus
                        maxLength="100"
                        className={!isValidEmail(this.state.email)?'error':''}
                        type="text"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      {!isValidEmail(this.state.email) ?
                        <HelpBlock className="error">Enter a valid email address</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                  </div>
                  :
                  <div>
                    <div className="row">
                      <ControlLabel className="col-md-3 col-sm-3 right-align">Username/FID:</ControlLabel>
                      <ControlLabel className="col-md-3 col-sm-4 left-align margin-bottom-15">{this.state.userName}</ControlLabel>
                    </div>
                    <div className="row">
                      <ControlLabel className="col-md-3 col-sm-3 right-align">Email:</ControlLabel>
                      <ControlLabel className="col-md-3 col-sm-4 left-align margin-bottom-15">{this.state.email}</ControlLabel>
                    </div>
                  </div>
                  }
                 <div className="row">
                    <ControlLabel className="col-md-3 col-sm-3 right-align margin-bottom-15">FCA/Non-FCA:</ControlLabel>
                    <ControlLabel className="col-md-3 col-sm-4 left-align">{this.state.authProvider === "COGNITO" ? "Non-FCA" : "FCA"}</ControlLabel>
                  </div>
                  <div className="row margin-bottom-15">
                    <ControlLabel className="col-md-3 col-sm-3 right-align">User Type:</ControlLabel>
                    { this.state.authProvider === "COGNITO" ?
                    <ControlLabel className="col-md-3 col-sm-4 left-align">{this.state.userType}</ControlLabel>
                    :
                    <div className="col-sm-9 left-align">
                      <select
                        value={this.state.userType}
                        className="col-sm-12"
                        onChange={this.handleChange.bind(this)}
                        id="userType" >
                        <option key="0" className="inactive" value="">Pick User Type</option>
                        <option key="1" value="ADMIN">ADMIN</option>
                        <option key="2" value="MANAGER">MANAGER</option>
                        <option key="3" value="STANDARD">STANDARD</option>
                      </select>
                    </div>
                    }
                  </div>
                  <div className="row">
                    <ControlLabel className="col-md-3 col-sm-3 right-align">User Role:</ControlLabel>

                    <FormGroup className="col-sm-9" controlId="ActiveRole" bsSize="large">
                      <RolePicker
                        roles={this.state.roles}
                        activeRole={this.state.userRole}
                        handleRoleChange={this.handleRoleChange.bind(this)}
                      />
                    </FormGroup>
                  </div>
                  <div className="row">
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="col-sm-4 btn-container spacer50">
                    <LoaderButton
                      id="submit-button"
                      className="red-button"
                      block
                      bsSize="large"
                      disabled={!this.validateForm()}
                      isLoading={this.state.isLoading}
                      type="submit"
                      text="Update User"
                      loadingText="Updating…"
                    />
                  </div>
                  <div className="col-sm-4 btn-container spacer50">
                     <LoaderButton
                       block
                       className="red-button"
                       bsSize="large"
                       disabled={!this.validateForm()}
                       isLoading={this.state.revertIsLoading}
                       text="Revert to Saved"
                       loadingText="Reverting to saved…"
                       onClick={this.handleRevert.bind(this)}
                     />
                   </div>
                </div>
              </form>
            </div>
            <br />
            <div className="row">
              <div key="shops">
                <div key="shopsTable" className="panel col-lg-9 col-sm-12">
                 <div key="shopTitle" className="panel shopTableTitle">
                   <div className="center text-bold">User's Groups</div>
                 </div>
                 <br />
                 <div className="auto-auth-table">
                    <ReactTable
                      columns={groupColDefs}
                      data={groups}
                      className="-highlight"
                      defaultPageSize={5}
                      defaultSorted={[
                        {
                          id: "name",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
          }
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>

        <Modal show={this.state.showUpdatedModal} onHide={this.handleHideUpdatedModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>User Updated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>User has been successfully updated.</p>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showConfirmNavModal} onHide={this.handleNavCancel.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>User Updated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have unsaved changes that will be lost if you navigate away.  Do you want to continue?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleNavConfirm.bind(this, this.state.newPage)}>Yes, Ignore Changes</Button>
            <Button onClick={this.handleNavCancel.bind(this)}>No, Go Back</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
