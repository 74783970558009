import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import AlertModal from "./components/AlertModal";
import Routes from "./Routes";
import "./stylesheets/header.css";
import "./stylesheets/App.css";
import "./stylesheets/footer.css";

class App extends Component {
  constructor(props) {
    super(props);

    window.addEventListener('orientationchange', this.handleChangeOrientation.bind(this));

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      alertMessage: '',
      showModal: false,
      revision: 0
    };
  }

  async componentDidMount() {
    try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    }
    catch(e) {
      if (e !== 'No current user' && e.message !== 'Refresh Token has expired') {
        console.log("Error getting a current session: ", e);
        this.setState({
          alertMessage: e.message,
          showModal: true
        });
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = (authenticated, newUser) => {
    if (newUser) {
      this.setState({ creatingUser: true });
    } else {
      this.setState({ creatingUser: false });
    }
    this.setState({ isAuthenticated: authenticated });
    if (!authenticated) {
      this.setState({ user: null });
      this.props.history.push("/");
    }
  }
  
  handleCancel = () => {
    this.setState({ showModal: false });
  }
  
  updateUserInfo = userInfo => {
    this.setState({user: userInfo, isLoading: false});
  }

  addEventListenerOnce(target, type, listener, addOptions, removeOptions) {
      target.addEventListener(type, function fn(event) {
          target.removeEventListener(type, fn, removeOptions);
          listener.apply(this, arguments);
      }, addOptions);
  }

  handleChangeOrientation = () => {
    this.addEventListenerOnce(window, 'resize', this.handleResize.bind(this));
  }

  handleResize = () => {
    console.log("Entering handleResize");
    this.setState({ revision: this.state.revision < 100 ? this.state.revision+1 : 0});
  }
  
  render() {
  
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      updateUserInfo: this.updateUserInfo,
      pathname: this.props.location.pathname,
      user: this.state.user,
      revision: this.state.revision
    };
    
    if (this.props.history.location.search !== '') {
      // Parse the url for values
      let searchStr = this.props.history.location.search;
      let values = searchStr.substring(searchStr.indexOf('?')+1);
      values.split("&").forEach((elem) => {
        let nameValue = elem.split('=');
        childProps[nameValue[0]] = nameValue[1];
      });
    }

    if (this.state.creatingUser) {
      childProps.creatingUser = true;
    }

    return (
      !this.state.isAuthenticating &&
      <div id="App" className={childProps.pathname === '/' ? "App introPage" : "App"}>
        <div className="row">
          <div className={ this.props.location.pathname !== '/' && this.props.location.pathname !== '/requestAccount' && this.props.location.pathname !== '/login' ? "header-logo" : "landing-logo"}>
            <div>
              <Link to="/">
                <img className="logo v-center center-block" alt="AutoAuth" src={ this.props.location.pathname === '/' ? '/img/AA_OEM_framed.png' : '/img/AA_OEM.png' } />
              </Link>
            </div>
          </div>
        </div>
        <div className="cover">
          <Routes childProps={childProps} />
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }
}

export default withRouter(App);
