import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import "./Welcome.css";

export default class ToolManufacturerCreate extends Component {
  constructor(props) {
    super(props);

    // Create Service Center Record
    if (props.history.location.state && props.history.location.state.company) {
      API.post("echo-dev", "/createManufacturer", { body: { content: props.history.location.state }});
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.setState({ isLoading: false });
  }
  
  renderLander() {
    
    return (
      <div className="home">
        <div className="panel welcome">
          <div className="center">Welcome to AutoAuth!</div>
          <div className="center">Click <Link to="/Home">here</Link> to get started</div>
        </div>
      </div>
    );
  }

  renderNotAuthenticated() {
    
    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): this.renderNotAuthenticated}
      </div>
    );
  }
}
