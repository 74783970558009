import React, { Component } from "react";
import "./NotFound.css";
import AutoAuthUnauthNavBar from "./AutoAuthUnauthNavBar";

export default class OEMSignup extends Component {

  render() {
    return (
      <div className="Signup">
        <AutoAuthUnauthNavBar
           pathname={this.props.pathname}>
        </AutoAuthUnauthNavBar>
        <h2 className="text-center">Coming Soon!</h2>
      </div>
    );
  }
}
