import React, { Component } from 'react';
import { getToolTypes } from '../libs/db-lib';
import {
  FormGroup,
  InputGroup,
  ControlLabel
} from "react-bootstrap";

export default class ToolPicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      manufacturerToolsList: [],
      manufacturers: [],
      toolList: [],
      currentManufacturer: '',
      currentManufacturerUUID: '',
      currentModel: '',
      currentModelUUID: ''
    }
  }

  async componentDidMount() {

    try {
      let result = await getToolTypes();
      let manufacturers = result.toolManufacturerList;
      let tools = [];
      this.setState({
              manufacturerToolsList: result.toolManufacturerList,
              manufacturers: manufacturers,
              toolList: tools,
              currentManufacturer: '',
              currentManufacturerUUID: '',
              currentModel: '',
              currentModelUUID: ''
            });
    } catch (e) {
      alert(e);
    }
  }

  handleManufacturerChange(event) {
    // Change the tools List
    let newManufacturer = event.target.value;

    let manRec = this.state.manufacturerToolsList.filter((man) => {
      return man.toolManufacturerUUID === newManufacturer;
    })[0];
    let tools = manRec.toolModelList;

    this.setState({
      toolList: tools,
      currentManufacturer: manRec.toolManufacturer,
      currentManufacturerUUID: newManufacturer,
      currentModel: '',
      currentModelUUID: ''
    });

    if (this.props.handleManufacturerChange) {
      this.props.handleManufacturerChange(newManufacturer);
    }
  }

  handleToolChange(event) {
    let newMan = {
      manufacturer: this.state.currentManufacturer,
      manufacturerUUID: this.state.currentManufacturerUUID
    }
    let toolRec = this.state.toolList.find((tool) => {return tool.toolModelUUID === event.target.value});
    let newTool = {
      modelUUID: toolRec.toolModelUUID,
      model: toolRec.toolModel
    }
    this.setState({
      currentModelUUID: toolRec.toolModelUUID,
      currentModel: toolRec.toolModel
    })
    if (this.props.handleToolChange) {
      this.props.handleToolChange(newMan, newTool);
    }
  }

  render() {

    const { currentManufacturerUUID, currentModelUUID } = this.state;

    let manufacturersOptions = [];
    manufacturersOptions.push(
      <option key="-1" className="inactive" value="" disabled>None Selected</option>
    );
    manufacturersOptions.push(
      this.state.manufacturers.map((man, i) => {
        return <option key={i} value={man.toolManufacturerUUID}>{man.toolManufacturer}</option>
      })
    );
    let toolOptions = [];
    toolOptions.push(
      <option key="-1" className="inactive" value="" disabled>None Selected</option>
    );
    toolOptions.push(
      this.state.toolList.map((tool, i) => {
        return <option key={i}value={tool.toolModelUUID}>{tool.toolModel}</option>
      })
    );


    return (
      <FormGroup className="col-sm-12" controlId="tool" bsSize="large">
        <InputGroup className="select-container col-sm-12">
          <ControlLabel className="col-sm-4">Manufacturer</ControlLabel>
          <div className="col-sm-5">
            <select
              value={currentManufacturerUUID}
              className="col-sm-12"
              onChange={this.handleManufacturerChange.bind(this)}
              id="manufacturers" >
              {manufacturersOptions}
            </select>
          </div>
        </InputGroup>

        <InputGroup className="select-container col-sm-12">
          <ControlLabel className="col-sm-4">Tool Model</ControlLabel>
          <div className="col-sm-5">
            <select
              value={currentModelUUID}
              className="col-sm-12"
              onChange={this.handleToolChange.bind(this)}
              id="tools">
              {toolOptions}
            </select>
          </div>
        </InputGroup>
      </FormGroup>
    )
  };
}
