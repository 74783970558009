import React, { Component } from "react";
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon
} from "react-bootstrap";
import ToolPicker from '../components/ToolPicker';
import AutoAuthNavBar from "./AutoAuthNavBar";
import { Icon } from "semantic-ui-react";
import { navbarSetup,
         resetUserData,
         toolSerialNoValidate,
         fieldChangeCase } from "../libs/utils";
import {
         Button,
         ButtonGroup,
         Modal } from 'react-bootstrap';
import { getTools, addTool, removeTool } from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import AlertModal from "../components/AlertModal";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageTools extends Component {

  constructor(props) {
    super(props);

    this.state = {
      serialNumber: "",
      manufacturer: {},
      model: {},
      alertMessage: '',
      showModal: false,
      filter: '',
      showDeleteModal: false,
      user: props.user,
      verifySerialNumber: false,
      tools: [],
      isLoading: true
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let tools = await getTools();
      this.setState({
        tools: tools.toolList ? tools.toolList : [],
        filteredTools: tools.toolList ? tools.toolList : [],
      });
    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({
      isLoading: false,
      windowWidth: window.outerWidth
    });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {

    let serialNumberValid = toolSerialNoValidate(this.state.serialNumber);

    return this.state.serialNumber.length > 0 &&
           this.state.manufacturer.manufacturerUUID &&
           this.state.model.modelUUID &&
           serialNumberValid;
  }

  activateSerialNumberVerify() {
    this.setState({ verifySerialNumber: true });
  }

  async handleConfirmModal() {

    // Handle delete of tool
    try {
      await removeTool(this.state.toolToDelete);
      this.handleRemoveTool(this.state.toolToDelete);
    } catch(e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }

    this.setState({ showDeleteModal: false, toolToDelete: '' });
  }

  handleCancelModal(e) {
    this.setState({ showDeleteModal: false, toolToDelete: '' });
  }

  handleDeleteButton(toolId, toolName) {

    this.setState({ showDeleteModal: true,
                    toolToDelete: toolId,
                    toolNameToDelete: toolName });
  }

  handleToolChange(manufacturer, model) {
    this.setState({ manufacturer: manufacturer,
                    model: model });
  }

  handleManufacturerChange(manufacturer) {
    this.setState({ manufacturer: manufacturer });
  }

  handleGridSizeChanged(sizeInfo) {
    sizeInfo.api.sizeColumnsToFit();
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }

  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleChange = event => {
    if (event.target.id === 'serialNumber') {
      fieldChangeCase(this, event.target, "upper");
      this.activateSerialNumberVerify();
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
      if (event.target.id === 'filter') {
        if (event.target.value.length > 0) {
          const filterArr = event.target.value ? event.target.value.split(' ').filter((f) => f !== '') : [];
          const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
          const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
          const toolList = this.state.tools.filter((t) => {
            return (
              regexStr.test(t.toolManufacturer) ||
              regexStr.test(t.toolModel) ||
              regexStr.test(t.toolSerial)
            );
          })
          this.setState({
            filteredTools: toolList ? toolList : [],
          });
        } else {
          this.setState({
            filteredTools: this.state.tools,
          });
        }
      }
    }
  }

  updateFilteredList(tools) {
    if (this.state.filter.length > 0) {
      const filterArr = this.state.filter.split(' ').filter((f) => f !== '');
      const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
      const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
      const toolList = tools.filter((t) => {
        return (
          regexStr.test(t.toolManufacturer) ||
          regexStr.test(t.toolModel) ||
          regexStr.test(t.toolSerial)
        );
      })
      this.setState({
        filteredTools: toolList ? toolList : [],
      });
    } else {
      this.setState({
        filteredTools: tools
      });
    }
  }

  handleRemoveTool(toolId) {
    // Update the tool table
    let tools = this.state.tools;
    let newTools = tools.filter((tool) => { return tool.toolID !== toolId; });
    this.setState({
      tools: newTools,
    });
    this.updateFilteredList(newTools);
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleSubmit = async event => {
    event.preventDefault();

    let data = this.state;

    const ownedTool = data.tools.find((t) => { return t.toolModel === data.model.model && t.toolSerial === data.serialNumber });
    if (ownedTool) {
      this.setState({
        alertMessage: "This tool is already entered in the system",
        showModal: true,
      });
    } else {
      this.setState({ isLoading: true });

      let results = await addTool(data.serialNumber, data.manufacturer.manufacturerUUID, data.model.modelUUID);
      if (results.error) {
        this.setState({
          alertMessage: results.error,
          showModal: true,
          isLoading: false
        });
      } else {
        let newTool = {toolManufacturer: data.manufacturer.manufacturer, toolModel: data.model.model, toolSerial: data.serialNumber, toolID: results.toolID};
        let tools = this.state.tools;
        tools.push(newTool);

        this.updateFilteredList(tools);

        this.setState({
          alertMessage: "Tool Added!",
          showModal: true,
          serialNumber: '',
          verifySerialNumber: false,
          tools: tools,
          isLoading: false
        });
      }
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push(
      { Header: "Manufacturer",
        accessor: "manufacturer",
        minWidth: 150 }
    );
    if (this.state.windowWidth > 500) {
      columnDefs.push(
        { Header: "Model",
          accessor: "model",
          minWidth: 100 }
      );
    }
    columnDefs.push(
      { Header: "Serial No.",
        id: "serialNo" ,
        accessor: "serialNo",
        className: 'tool-sn-font',
        minWidth: (this.state.windowWidth > 600)?200:100 }
    );
    columnDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "toolId",
        accessor: "toolId",
        maxWidth: (this.state.windowWidth > 500)?90:50,
        Cell: (row) => {
          return (
            <div>
              <ButtonGroup>
                <Button bsStyle="link" href="#" onClick={this.handleDeleteButton.bind(this, row.original.toolId, row.original.serialNo)}><Icon title="Remove from System" name="times circle outline" /></Button>
              </ButtonGroup>
            </div>
          );
        },
        suppressSorting: true,
        suppressFilter: true,
      }
    );

    let tools = this.state.filteredTools ?
      this.state.filteredTools.map((tool) => {
        return {
          toolId: tool.toolID,
          manufacturer: tool.toolManufacturer,
          model: tool.toolModel,
          serialNo: tool.toolSerial,
        }
      })
      : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          shop={navbarData.shop}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <div className="panel-frame">

          {this.state.user && this.state.user.shop !== '' ?

          <Tabs defaultActiveKey={1} id="tools-management-tabs">
            <Tab key="1" eventKey={1} title="System Tools">
              <TabContent>
                { !this.state.isLoading ?
                <div className={this.state.windowWidth > 400 ? "col-lg-9 col-md-10 text-center" : "col-lg-9 col-md-10 text-center no-left-padding"} >
                  <br />
                  <div className="auto-auth-table col-sm-12">
                    <FormGroup controlId="filter" bsSize="large">
                      <ControlLabel className={this.state.filterFieldActivate || this.state.filter.length > 0 ? "float-label field-active" : "float-label"}>Filter</ControlLabel>
                      <FormControl
                        maxLength="50"
                        type="text"
                        value={this.state.filter}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                    </FormGroup>
                    <ReactTable
                      columns={columnDefs}
                      data={tools}
                      className="-highlight"
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: "serialNo",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            <Tab key="2" eventKey={2} title="Add Tool">
              <TabContent>
                <form onSubmit={this.handleSubmit}>
                  <div className="col-sm-8 text-center">
                    <br />
                    <div className="center text-bold">Pick the Type of Tool</div>
                    <br />
                    <ToolPicker
                      manufacturer={this.state.manufacturer.manufacturerUUID ? this.state.manufacturer.manufacturerUUID : ''}
                      model={this.state.model.modelUUID ? this.state.model.modelUUID : ''}
                      handleToolChange={this.handleToolChange.bind(this)}
                      handleManufacturerChange={this.handleManufacturerChange.bind(this)}
                    />
                   <ControlLabel className="col-sm-4">Serial Number</ControlLabel>
                   <FormGroup className="col-sm-8" controlId="serialNumber" bsSize="large">
                      <FormControl
                        autoFocus
                        className={this.state.verifySerialNumber && !toolSerialNoValidate(this.state.serialNumber)?'tool-sn-font error':'tool-sn-font'}
                        type="text"
                        maxLength="100"
                        placeholder="Serial Number"
                        value={this.state.serialNumber}
                        onChange={this.handleChange}
                      />
                      { this.state.verifySerialNumber && !toolSerialNoValidate(this.state.serialNumber) ?
                        <HelpBlock className="error">Serial Number cannot be blank and can only contain alphanumberic plus '-_/.' characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup>
                      <div className="col-sm-12">
                        <LoaderButton
                          block
                          className="btn-container red-button"
                          bsSize="large"
                          disabled={!this.validateForm()}
                          type="submit"
                          isLoading={this.state.isLoading}
                          text="Add"
                          loadingText="Adding tool…"
                        />
                      </div>
                    </FormGroup>

                  </div>
                </form>
              </TabContent>
            </Tab>
          </Tabs>
          : '' }
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
        <Modal show={this.state.showDeleteModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Remove Tool from System</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove the tool {this.state.toolNameToDelete}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleConfirmModal.bind(this)}>Confirm</Button>
            <Button onClick={this.handleCancelModal.bind(this)}>Cancel</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}
