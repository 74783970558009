import React, { Component } from "react";
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  InputGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Modal
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import { navbarSetup,
         resetUserData,
         passwordValidate,
         nameValidate,
         isValidEmail,
         usernameValidate } from "../libs/utils";
import {
         Button } from 'react-bootstrap';
import { getUsers, getRoles, addUsers, getUserRecord, updateUserState } from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import AlertModal from "../components/AlertModal";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageUsers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      userName: '',
      showDisableModal: false,
      user: props.user,
      userList: null,
      filter: "",
      userToDisable: '',
      userNameToDisable: '',
      authProvider: '',
      userType: '',
      userTypes: [
        'ADMIN',
        'MANAGER',
        'STANDARD'
      ],
      username: '',
      firstName: '',
      lastName: '',
      email: "",
      password: '',
      confirmPassword: "",
      verifyFirstName: false,
      verifyLastName: false,
      verifyEmail: false,

    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let users = await getUsers();
      let roles = await getRoles();
      let userList = users;

      this.setState({
        users: users,
        userList: userList ? userList : [],
        roles: roles ? roles : []
      });

    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    let passwordValid = this.state.authProvider === 'FCA_ADFS' ? true : passwordValidate(this.state.password);
    let usernameValid = usernameValidate(this.state.userName);
    let namesValid = nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);

    return (
      this.state.userName.length > 0 &&
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      this.state.userType.length > 0 &&
      passwordValid &&
      usernameValid &&
      namesValid &&
      emailValid &&
      this.state.password === this.state.confirmPassword
    );
  }

  async handleConfirmModal() {

    try {
    // Handle disable group
      await updateUserState(this.state.userToDisable, 'INACTIVE');
    } catch(e) {
      this.setState({
        showDisableModal: false,
        alertMessage: e.message,
        userToDisable: '',
        userNameToDisable: '',
        showModal: true,
        isLoading: false
      });
    }

    let users = this.state.userList;
    let userIndex = users.findIndex((u) => {
      return u.userID === this.state.userToDisable;
    });
    if (userIndex !== -1) {
      users[userIndex].userState = 'INACTIVE';
    }

    this.setState({ showDisableModal: false,
                    userToDisable: '',
                    userNameToDisable: '',
                    userList: users });
  }

  handleCancelModal(e) {
    e.preventDefault();

    this.setState({ showDisableModal: false,
                    userToDisable: '',
                    userNameToDisable: '' });
  }

  handleDisableButton(userId) {

    let user = this.state.userList.find(user => user.userID === userId);
    let userName = user.firstName + ' ' + user.lastName;
    this.setState({ showDisableModal: true,
                    userToDisable: userId,
                    userNameToDisable: userName });
  }

  async handleEnableButton(userId) {

    try {
      await updateUserState(userId, 'ACTIVE');
    } catch(e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }

    let users = this.state.userList;
    let userIndex = users.findIndex((u) => {
      return u.userID === userId;
    });
    if (userIndex !== -1) {
      users[userIndex].userState = 'ACTIVE';
    }

    this.setState({ userList: users });
  }

  handleEditButton(userId) {
    // Get the user info for this user
    let user = this.state.userList.find(user => user.userID === userId);
    this.props.history.push({pathname: "/manageUsers/editUser/", state: { editUser: user }});
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    if (event.target.id === 'authProvider' &&
        event.target.value === 'FCA_ADFS') {
      this.setState({
        password: '',
        passwordFieldActivate: false,
        confirmPassword: '',
        confirmPasswordFieldActivate: false,
      });
    } else if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        const filterArr = event.target.value ? event.target.value.split(' ').filter((f) => f !== '') : [];
        const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const userList = this.state.users.filter((u) => {
          let roleListStr = '';
          if (u.userRoleList.length > 0) {
            roleListStr = u.userRoleList.length > 1 ?
              u.userRoleList.reduce(((f1, f2) => {
                let role1 = this.state.roles.find((r) => r.roleID === f1);
                let role2 = this.state.roles.find((r) => r.roleID === f2);
                const role1Str = role1 ? role1.roleName : '';
                const role2Str = role2 ? role2.roleName : '';
                return role1Str + ' ' + role2Str;
              }))
            : this.state.roles.find((r) => r.roleID === u.userRoleList[0]).roleName;
          } else {
            roleListStr = "<No Roles Allowed>";
          }
          return (
            regexStr.test(u.firstName) ||
            regexStr.test(u.lastName) ||
            regexStr.test(u.userType) ||
            regexStr.test(u.userName) ||
            regexStr.test(u.userState) ||
            regexStr.test(roleListStr)
          );
        })
        this.setState({
          userList: userList ? userList : [],
        });
      } else {
        this.setState({
          userList: this.state.users,
        });
      }
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }

  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleSelectTools = event => {
    let user = getUserRecord();
    let shopRec = user.userShops.filter((shop) => { return shop.shopName === this.state.user.shop; })[0];
    this.setState({
      shopTools: shopRec.allShopTools
    });
  }

  handleRemoveTool(toolId) {
    // Update the tool table
    let shopTools = this.state.shopTools;
    let newShopTools = shopTools.filter((tool) => { return tool.toolID !== toolId; });
    this.setState({ shopTools: newShopTools });
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  updateFilteredList(users) {
    if (this.state.filter.length > 0) {
      const filterArr = this.state.filter.split(' ').filter((f) => f !== '');
      const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
      const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
      const userList = users.filter((u) => {
        let roleListStr = '';
        if (u.userRoleList.length > 0) {
          roleListStr = u.userRoleList.length > 1 ?
            u.userRoleList.reduce(((f1, f2) => {
              let role1 = this.state.roles.find((r) => r.roleID === f1);
              let role2 = this.state.roles.find((r) => r.roleID === f2);
              const role1Str = role1 ? role1.roleName : '';
              const role2Str = role2 ? role2.roleName : '';
              return role1Str + ' ' + role2Str;
            }))
          : this.state.roles.find((r) => r.roleID === u.userRoleList[0]).roleName;
        }
        return (
            regexStr.test(u.firstName) ||
            regexStr.test(u.lastName) ||
            regexStr.test(u.userType) ||
            regexStr.test(u.userName) ||
            regexStr.test(u.state) ||
            regexStr.test(roleListStr)
        );
      })
      this.setState({
        userList: userList ? userList : [],
      });
    } else {
      this.setState({
        userList: users
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let data = this.state;
    let newUser = {
      userName: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      email: data.email,
      userType: data.userType,
      authProvider: data.authProvider,
    };
    let newUserArr = [];
    newUserArr.push(newUser);
    let results = await addUsers(newUserArr);
    if (results.error) {
      this.setState({
        alertMessage: results.error,
        showModal: true,
        isLoading: false
      });
    } else {
      let users = this.state.users ? this.state.users : [];
      let newUser = results.userList[0];
      if (newUser.creationStatus !== 'SUCCESS') {
        this.setState({
          alertMessage: newUser.statusMsg,
          showModal: true,
          isLoading: false
        });
      } else {
        newUser.firstName = data.firstName;
        newUser.lastName = data.lastName;
        newUser.email = data.email;
        newUser.userType = data.userType;
        newUser.userState = 'PENDING';
        newUser.authProvider = data.authProvider;
        newUser.userRoleList = [];

        users.push(newUser);

        this.updateFilteredList(users);

        this.setState({
          users: users,
          alertMessage: "User Added!",
          showModal: true,
          isLoading: false,
          userName: '',
          firstName: '',
          lastName: '',
          email: '',
          userType: '',
          password: '',
          confirmPassword: ''
        });
      }
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push(
      { Header: "Name",
        accessor: "name",
        minWidth: 150 }
    );
    columnDefs.push(
      { Header: "FCA/Non-FCA",
        id: 'authProvider',
        accessor: (u) => {
          return u.authProvider === "COGNITO" ? "Non-FCA" : "FCA";
        },
        minWidth: 100 }
    );
    columnDefs.push(
      { Header: "Username/FID",
        accessor: "userName",
        minWidth: 100 }
    );
    columnDefs.push(
      { Header: "User Type",
        accessor: "userType",
        minWidth: 100 }
    );
    columnDefs.push(
      { Header: "Role",
        id: "roleList",
        accessor: "userRoles",
        Cell: (row) => {
          let roleList = row.original.userRoles;
          if (!roleList || roleList.length === 0) {
            return "<No Roles Allowed>";
          } else {
            let roleNames = roleList.map((ur) => {
              let roleRec = this.state.roles.find((r) => r.roleID === ur);
              if (roleRec) {
                return roleRec.roleName;
              } else {
                return '';
              }
            });

            return  (
              <div>
                { roleNames.map((name, i) => <div key={i}>{name}</div> ) }
              </div>
            );
          }
        },
        sortMethod: (a, b, desc) => {
          const reducer = (a, c) => a + ',' + c;
          const aArr = a ? a : [];
          const bArr = b ? b : [];

          let roleListStrA = '';
          let roleListStrB = '';

          if (aArr.length > 0) {
            roleListStrA = aArr.map((ur) => {
              let roleRec = this.state.roles.find((r) => r.roleID === ur);
              if (roleRec) {
                return roleRec.roleName;
              } else {
                return '<No Roles Allowed>';
              }
            }).reduce(reducer);
          }

          if (bArr.length > 0) {
            roleListStrB = bArr.map((ur) => {
              let roleRec = this.state.roles.find((r) => r.roleID === ur);
              if (roleRec) {
                return roleRec.roleName;
              } else {
                return '<No Roles Allowed>';
              }
            }).reduce(reducer);
          }

          if (desc) {
            if (roleListStrA > roleListStrB) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (roleListStrA < roleListStrB) {
              return -1;
            } else {
              return 1;
            }
          }
        },
        minWidth: 250 }
    );
     if (this.state.windowWidth > 500) {
      columnDefs.push(
        { Header: "State",
          accessor: "state",
          minWidth: 100,
          className: "text-center" }
      );
    }
   columnDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "userId",
        accessor: "userId",
        minWidth: 80,
        maxWidth: 80,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <ButtonGroup>
                { row.original.userID !== this.state.user.user.userID ?
                  <div>
                    <Button bsStyle="link" href="#" onClick={this.handleEditButton.bind(this, row.original.userID)}><Icon title="Edit user" name="edit" /></Button>
                    { row.original.state === 'ACTIVE' ?
                    <Button bsStyle="link" href="#" onClick={this.handleDisableButton.bind(this, row.original.userID)}><Icon title="Disable User" name="times circle outline" /></Button>
                    :
                    <Button bsStyle="link" href="#" onClick={this.handleEnableButton.bind(this, row.original.userID)}><Icon title="Enable User" name="check circle outline" /></Button>
                    }
                  </div>
                : "" }
              </ButtonGroup>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let userTypesOptions = this.state.authProvider === 'FCA_ADFS' ?
      [
        <option key="0" className="inactive" value="">Pick User Type</option>,
        <option key="1" value="ADMIN">ADMIN</option>,
        <option key="2" value="MANAGER">MANAGER</option>,
        <option key="3" value="STANDARD">STANDARD</option>,
      ]
    :
      [
        <option key="0" className="inactive" value="">Pick User Type</option>,
        <option key="3" value="STANDARD">STANDARD</option>,
      ];

    let users = this.state && this.state.userList && this.state.userList.length > 0 ?
      this.state.userList.map((user) => {
        return {
          userID: user.userID,
          name: user.firstName + ' ' + user.lastName,
          firstName: user.firstName,
          lastName: user.lastName,
          userName: user.userName,
          userRoles: user.userRoleList,
          userType: user.userType,
          authProvider: user.authProvider,
          state: user.userState
        }
      })
      : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">

          <Tabs defaultActiveKey={1} id="tools-management-tabs">
            <Tab key="1" eventKey={1} title="Users">
              <TabContent>
                { this.state.userList ?
                <div className={this.state.windowWidth > 400 ? "col-lg-10 col-md-11" : "col-lg-10 col-md-11 no-left-padding"} >
                  <br />
                  <div className="auto-auth-table col-sm-12">
                    <FormGroup controlId="filter" bsSize="large">
                      <ControlLabel className={this.state.filterFieldActivate || this.state.filter.length > 0 ? "float-label field-active" : "float-label"}>Filter</ControlLabel>
                      <FormControl
                        maxLength="50"
                        type="text"
                        value={this.state.filter}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                    </FormGroup>
                    <ReactTable
                      columns={columnDefs}
                      data={users}
                      className="-highlight"
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: "name",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            <Tab key="2" eventKey={2} title="Add User">
              <TabContent>
                <div className="col-sm-4">
                  <form onSubmit={this.handleSubmit}>
                    <FormGroup>
                      <br />
                      <h3 className="text-center">Single User Create</h3>
                    </FormGroup>
                    <InputGroup className="select-container-pad-20 col-sm-12">
                        <select
                          value={this.state.authProvider}
                          className="col-sm-12"
                          onChange={this.handleChange.bind(this)}
                          id="authProvider" >
                          <option key="0" className="inactive" value="">Pick Authentication Provider</option>
                          <option key="1" value="COGNITO">NON-FCA</option>
                          <option key="2" value="FCA_ADFS">FCA</option>
                        </select>
                    </InputGroup>
                    <InputGroup className="select-container-pad-20 col-sm-12">
                        <select
                          value={this.state.userType}
                          className="col-sm-12"
                          onChange={this.handleChange.bind(this)}
                          id="userType" >
                          { userTypesOptions.map(o => o ) }
                        </select>
                    </InputGroup>
                    <FormGroup controlId="userName" bsSize="large">
                      <ControlLabel className={this.state.usernameFieldActivate || this.state.userName.length > 0 ? "float-label field-active" : "float-label"}>Username/FID</ControlLabel>
                      <FormControl
                        className={this.state.userName.length > 0 && !usernameValidate(this.state.userName)?'error':''}
                        maxLength="50"
                        type="text"
                        value={this.state.userName}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.userName.length > 0 && !usernameValidate(this.state.userName) ?
                        <HelpBlock className="error">Username must be at least 7 alphanumeric lowercase characters starting with a letter</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="firstName" bsSize="large">
                      <ControlLabel className={this.state.firstNameFieldActivate || this.state.firstName.length > 0 ? "float-label field-active" : "float-label"}>First Name</ControlLabel>
                      <FormControl
                        className={this.state.verifyFirstName && !nameValidate(this.state.firstName)?'error':''}
                        maxLength="50"
                        type="text"
                        value={this.state.firstName}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      { this.state.verifyFirstName && !nameValidate(this.state.firstName) ?
                        <HelpBlock className="error">First name cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="lastName" bsSize="large">
                      <ControlLabel className={this.state.lastNameFieldActivate || this.state.lastName.length > 0 ? "float-label field-active" : "float-label"}>Last Name</ControlLabel>
                      <FormControl
                        className={this.state.verifyLastName && !nameValidate(this.state.lastName)?'error':''}
                        maxLength="50"
                        type="text"
                        value={this.state.lastName}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                     />
                      { this.state.verifyLastName && !nameValidate(this.state.lastName) ?
                        <HelpBlock className="error">Last name cannot be blank and cannot contain special characters</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="email" bsSize="large">
                      <ControlLabel className={this.state.emailFieldActivate || this.state.email.length > 0 ? "float-label field-active" : "float-label"}>Email</ControlLabel>
                      <FormControl
                        className={this.state.verifyEmail && !isValidEmail(this.state.email)?'error':''}
                        maxLength="100"
                        type="text"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      {this.state.verifyEmail && !isValidEmail(this.state.email) ?
                        <HelpBlock className="error">Enter a valid email address</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large">
                      <ControlLabel className={this.state.passwordFieldActivate || this.state.password.length > 0 ? "float-label field-active" : "float-label"}>Password</ControlLabel>
                      <FormControl
                        className={this.state.password.length > 0 && !passwordValidate(this.state.password) ?'error':''}
                        maxLength="50"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        type="password"
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                        disabled={this.state.authProvider === "FCA_ADFS"}
                      />
                      { this.state.password.length > 0 && !passwordValidate(this.state.password) ?
                        <HelpBlock className="error">Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup controlId="confirmPassword" bsSize="large">
                      <ControlLabel className={this.state.confirmPasswordFieldActivate || this.state.confirmPassword.length > 0 ? "float-label field-active" : "float-label"}>Confirm Password</ControlLabel>
                      <FormControl
                        className={this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword?'error':''}
                        maxLength="50"
                        value={this.state.confirmPassword}
                        onChange={this.handleChange.bind(this)}
                        type="password"
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                        disabled={this.state.authProvider === "FCA_ADFS"}
                      />
                      { this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword ?
                        <HelpBlock className="error">Password and Confirm Password do not match</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    { this.props.inviteCode === "1" ?
                    <FormGroup controlId="inviteCode" bsSize="large">
                      <ControlLabel className={this.state.inviteCodeFieldActivate || this.state.inviteCode.length > 0 ? "float-label field-active" : "float-label"}>Invite Code</ControlLabel>
                      <FormControl
                        className={this.state.inviteCode && this.state.inviteCode.length === 0?'error':''}
                        maxLength="50"
                        type="text"
                        value={this.state.inviteCode}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                      {this.state.verifyInviteCode && this.state.inviteCode.length === 0 ?
                        <HelpBlock className="error">Invite Code cannot be blank</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    : '' }
                    <div className="btn-container">
                      <LoaderButton
                        id="submit-button"
                        className="red-button"
                        block
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create User"
                        loadingText="Creating…"
                      />
                    </div>
                  </form>
                </div>
                <div className="col-sm-6">
                </div>
              </TabContent>
            </Tab>
          </Tabs>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>

        <Modal show={this.state.showDisableModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Remove user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to disable the user: {this.state.userNameToDisable}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleConfirmModal.bind(this, this.state.userToDisable)}>Confirm</Button>
            <Button onClick={this.handleCancelModal.bind(this)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
