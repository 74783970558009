import React, { Component } from "react";
import { Link } from "react-router-dom";
import { createUser } from "../libs/db-lib";
import "./Welcome.css";

export default class UserCreate extends Component {
  constructor(props) {
    super(props);

    // Create User Record
    if (props.history.location.state && props.history.location.state.userName) {
      let userData = props.history.location.state;
      createUser(userData.userName, userData.firstName, userData.lastName, userData.email);
      this.props.userHasAuthenticated(true);
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    this.state = { 
      isLoading: false,
      creatingUser: false 
    };
  }
  
  renderLander() {
    
    return (
      <div className="home">
        <div className="panel welcome">
          <div className="center">Welcome to AutoAuth!</div>
          <div className="center">Click <Link to="/Home">here</Link> to get started</div>
        </div>
      </div>
    );
  }

  renderNotAuthenticated() {
  
    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): this.renderNotAuthenticated}
      </div>
    );
  }
}
