import DateRangePicker from 'react-bootstrap-daterangepicker';
import React, { Component } from 'react';
import moment from 'moment';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../stylesheets/daterangepicker.css';

import {
  DropdownButton,
  FormControl,
  FormGroup,
  MenuItem,
} from 'react-bootstrap';

const locale = {
  format: 'YYYY-MM-DD HH:mm:ss',
  separator: ' to ',
};

// The 'range' property is a 2-element array containing functions that return a particular moment in
// time. This is done so that these moments can be lazily computed when a saved search is loaded.
const ranges = [
  {
    name: 'Today',
    range: [() => moment.utc().startOf('day'), () => moment.utc().endOf('day')],
  },
  {
    name: 'Tomorrow',
    range: [() => moment.utc().add(1, 'days').startOf('day'), () => moment.utc().add(1, 'days').endOf('day')],
  },
  {
    name: '24 Hours',
    range: [() => moment.utc(), () => moment.utc().add(1, 'days') ],
  },
  {
    name: 'Next 5 Days',
    range: [() => moment.utc().startOf('day'), () => moment.utc().add(4, 'days').startOf('day')],
  },
];

export const updateDateRange = ({ s, e, name }) => {
  const preset = ranges.find((p) => p.name === name);
  const start = (preset !== undefined) ? preset.range[0]() : s;
  const end = (preset !== undefined) ? preset.range[1]() : e;
  return { start, end, name };
};

export default class CustomDatePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      start: props.value.start,
      end: props.value.end,
      rangeName: 'Custom',
    };
  }

  componentDidMount() {
    document.getElementById('dateRange').value = this.calcRangeStr(this.state.start, this.state.end);
  }

  handleRangeChange(range) {
    document.getElementById('dateRange').value = this.calcRangeStr(range.start, range.end);
    if (this.props.onChange) {
      this.props.onChange({
        start: range.start,
        end: range.end,
      });
    }
  }

  handlePresetChange(name, range, event) {

    if (this.props.onChange) {
      this.props.onChange({
        start: range[0](),
        end: range[1](),
      });
    }
    this.setState({'rangeName': name});
  }

  calcRangeStr(start, end) {
    return start.utc().format(locale.format) +
           locale.separator +
           end.utc().format(locale.format) +
           " UTC";
  }

  render() {
    let label = this.calcRangeStr(this.props.value.start, this.props.value.end)
    return (
      <div>
        <FormGroup>
          <DateRangePicker
            useCurrent={false}
            startDate={this.props.value.start}
            endDate={this.props.value.end}
            locale={locale}
            containerClass="width-100"
            showDropdowns
            onApply={(event, dateRangePicker) => {
              const { startDate, endDate } = dateRangePicker;
              this.handleRangeChange({ start: startDate.utc(true), end: endDate.utc(true), name: 'Custom' });
              this.setState({rangeName: 'Custom'});
            }}
            alwaysShowCalendars
            timePicker
          >
          <FormControl
            id="dateRange"
            className="col-sm-12"
            type="text"
            value={label}
            onChange={() => { return true;}}
          />

          </DateRangePicker>
        </FormGroup>
        <FormGroup>
          <DropdownButton
            id="date-range-interval-dropdown"
            title={this.state.rangeName}
          >
            {ranges.map(({ name, range }) => (
              <MenuItem
                key={name}
                onClick={this.handlePresetChange.bind(this, name, range)}
              >
                {name}
              </MenuItem>
            ))}
          </DropdownButton>
        </FormGroup>
      </div>
    );
  }
};
