import React, { Component } from "react";
import { Navbar, NavItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../stylesheets/NavBar.css";

export default class AutoAuthUnauthNavBar extends Component {
  
  render() {
    return (
      <Navbar collapseOnSelect>
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to="/">
              <img className="brand-image" alt="AutoAuth" src='img/AutoAuth-transparent-TM.png' />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <div>
            <ul className="nav navbar-nav">
              <li>
                <NavLink className={(this.props.pathname === "/login")?"nav-item login selected":"nav-item login"} to="/login">
                  Login
                </NavLink>
              </li>
              {this.props.inviteCode === "1" || this.props.inviteCode === 1 ?
              <li>
                <NavLink className={(this.props.pathname === "/requestInvite")?"nav-item selected":"nav-item"} to={"/requestInvite?inviteCode="+this.props.inviteCode}>
                  Request Invite Code
                </NavLink>
              </li>
              : '' }
              {this.props.inviteCode === "1" || this.props.inviteCode === 1 ?
              <NavItem className="header-text"><span className="header-text">|</span></NavItem>
              : '' }
              <li>
                <NavLink className={(this.props.pathname === "/signup")?"nav-item selected":"nav-item"} to={"/signup?inviteCode="+(this.props.inviteCode?this.props.inviteCode:0)}>
                  User
                </NavLink>
              </li>
              <NavItem className="header-text"><span className="header-text">|</span></NavItem>
              <li>
                <NavLink className={(this.props.pathname === "/serviceCenterSignup")?"nav-item selected":"nav-item"} to={"/serviceCenterSignup?inviteCode="+(this.props.inviteCode?this.props.inviteCode:0)}>
                  Service Center or Independent Technician
                </NavLink>
              </li>
            </ul>
          </div>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
