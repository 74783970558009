import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import { passwordValidate, fieldChangeCase } from "../libs/utils";
import AlertModal from "../components/AlertModal";
import "./Signup.css";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };
  }
  
  validateForm() {
    return (
      this.state.username.length > 0
    );
  }

  validateConfirmationForm() {
    let passwordValid = passwordValidate(this.state.password);
    return this.state.confirmationCode.length > 0 &&
           passwordValid &&
           this.state.password === this.state.confirmPassword;
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }
  
  handleChange = event => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, "lower");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }
  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const newUser = await Auth.forgotPassword(this.state.username);
      this.setState({
        newUser
      });
    } catch (e) {
      let alertMsg = e.message;
      if (alertMsg === 'Username/client id combination not found.')  {
        alertMsg = "Username or Email Address is not found.";
      }
      this.setState({ 
        alertMessage: alertMsg,
        showModal: true,
        isLoading: false
      });
    }

    this.setState({ isLoading: false });
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.forgotPasswordSubmit(this.state.username, this.state.confirmationCode, this.state.password);
      await Auth.signIn(this.state.username, this.state.password);

      this.props.userHasAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      this.setState({ 
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({ isLoading: false });
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel className={this.state.passwordFieldActivate || this.state.password.length > 0 ? "float-label field-active" : "float-label"}>Password</ControlLabel>
          <FormControl
            className={this.state.password.length > 0 && !passwordValidate(this.state.password) ?'error':''}
            maxLength="50"
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
          />
          { this.state.password.length > 0 && !passwordValidate(this.state.password) ?
            <HelpBlock className="error">Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter</HelpBlock>
            : ''
          }
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel className={this.state.confirmPasswordFieldActivate || this.state.confirmPassword.length > 0 ? "float-label field-active" : "float-label"}>Confirm Password</ControlLabel>
          <FormControl
            className={this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword ?'error':''}
            maxLength="50"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
          />
          { this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword ?
            <HelpBlock className="error">Password and Confirm Password do not match</HelpBlock>
            : ''
          }
        </FormGroup>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel className={this.state.confirmationCodeFieldActivate || this.state.confirmationCode.length > 0 ? "float-label field-active" : "float-label"}>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            maxLength="20"
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          className="red-button"
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form className="white-container" onSubmit={this.handleSubmit}>
        <FormGroup>
          <h2 className="text-center">Reset Password</h2>
        </FormGroup>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel className={this.state.usernameFieldActivate || this.state.username.length > 0 ? "float-label field-active" : "float-label"}>Username or Email Address</ControlLabel>
          <FormControl
            autoFocus
            maxLength="100"
            type="text"
            value={this.state.username}
            onChange={this.handleChange}
            onFocus={this.activateField.bind(this)}
            onBlur={this.disableField.bind(this)}
          />
        </FormGroup>
        <LoaderButton
          className="red-button"
          block
          bsSize="large"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Request Reset"
          loadingText="Sending Request…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }
}
