import React, { Component } from "react";
import moment from 'moment';
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  Checkbox,
  ControlLabel,
  FormControl,
  Glyphicon,
  Modal
} from "react-bootstrap";
import async from 'async';
import { Icon } from "semantic-ui-react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import AlertModal from "../components/AlertModal";
import { navbarSetup,
         resetUserData } from "../libs/utils";
import { Button } from 'react-bootstrap';
import CustomDatePicker from '../components/CustomDatePicker';
import {
  getRoles,
  getUsers,
  getGroupUsers,
  getGroupRoles,
  getGroupInfo,
  updateGroupInfo,
  addRoleToGroup,
  removeRoleFromGroup,
  addUserToGroup,
  removeUserFromGroup  } from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class EditGroup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showUpdatedModal: props.match.params.updated,
      showConfirmNavModal: false,
      groupName: '',
      showDeleteModal: false,
      user: props.user,
      group: props.match.params.group,
      rolesToDelete: [],
      rolesToAdd: [],
      usersToDelete: [],
      usersToAdd: [],
      newPage: '',
      isLoading: false,
      revertIsLoading: false
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let group = {};
      if (this.props.location.state.group) {
        group = this.props.location.state.group;
      } else {
        group = await getGroupInfo(this.props.location.state.groupID);
      }
      if (group && group.validityParams && typeof(group.validityParams.startDate) === 'string') {
        group.validityParams.startDateStr = group.validityParams.startDate;
        group.validityParams.startDate = moment(new Date(group.validityParams.startDate));
        group.validityParams.endDateStr = group.validityParams.endDate;
        group.validityParams.endDate = moment(new Date(group.validityParams.endDate));
      }
      let groupRoles = await getGroupRoles(group.groupID);
      let origGroupRoles = [...groupRoles];
      let groupUsers = await getGroupUsers(group.groupID);
      let origGroupUsers = [...groupUsers];
      let roles = await getRoles();
      let users = await getUsers();

      this.setState({
        group: group,
        groupName: group ? group.groupName : '',
        groupState: group ? group.groupState : '',
        validityCriteria: group ? group.validityCriteria : '',
        validityParams: group && group.validityParams ? group.validityParams : { startDate: moment.utc().startOf('day'), endDate: moment.utc().endOf('day') },
        groupRoles: groupRoles ? groupRoles : [],
        origGroupRoles: origGroupRoles ? origGroupRoles : [],
        groupUsers: groupUsers ? groupUsers : [],
        origGroupUsers: origGroupUsers ? origGroupUsers : [],
        roles: roles ? roles : [],
        users: users ? users : [],
      });

    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false,
        revertIsLoading: false
      });
    }
  }

  validateForm() {

    let rolesToAdd = this.state.groupRoles ?
      this.state.groupRoles.filter((role) => {
        return this.state.origGroupRoles.findIndex((orole) => role.roleID === orole.roleID) === -1;
      })
    : [];

    let rolesToRemove = this.state.origGroupRoles ?
      this.state.origGroupRoles.filter((role) => {
        return this.state.groupRoles.findIndex((orole) => role.roleID === orole.roleID) === -1;
      })
    : [];

    let usersToAdd = this.state.groupUsers ?
      this.state.groupUsers.filter((user) => {
        return this.state.origGroupUsers.findIndex((ouser) => user.userID === ouser.userID) === -1;
      })
    : [];

    let usersToRemove = this.state.origGroupUsers ?
      this.state.origGroupUsers.filter((user) => {
        return this.state.groupUsers.findIndex((ouser) => user.userID === ouser.userID) === -1;
      })
    : [];

    let valid =
      this.state.groupName.length > 0 &&
      (this.state.groupName !== this.state.group.groupName ||
       this.state.validityCriteria !== this.state.group.validityCriteria ||
      (this.state.validityCriteria === "TIME" &&
       this.state.validityParams && (
       !this.state.group.validityParams ||
       this.state.validityParams.startDate !== this.state.group.validityParams.startDate ||
       this.state.validityParams.endDate !== this.state.group.validityParams.endDate)) ||
       this.state.groupState !== this.state.group.groupState ||
       rolesToAdd.length > 0 ||
       rolesToRemove.length > 0 ||
       usersToAdd.length > 0 ||
       usersToRemove.length > 0
      );

    return valid;
  }

  handleDeleteCheckboxChange(roleID) {
    let rolesToDelete = [...this.state.rolesToDelete];
    let checked = document.getElementById("remove-"+roleID).checked;
    if(checked) {
      rolesToDelete.push(roleID);
      this.setState({ rolesToDelete: rolesToDelete });
    } else {
      let newRolesToDelete = rolesToDelete.filter((id) => { return !(id === roleID) });
      this.setState({ rolesToDelete: newRolesToDelete });
    }
  }

  handleAddCheckboxChange(roleID) {
    let checked = document.getElementById("add-"+roleID).checked;
    let rolesToAdd = [...this.state.rolesToAdd];
    if(checked) {
      rolesToAdd.push(roleID);
      this.setState({ rolesToAdd: rolesToAdd });
    } else {
      let newRolesToAdd = rolesToAdd.filter((id) => { return !(id === roleID) });
      this.setState({ rolesToAdd: newRolesToAdd });
    }
  }

  handleDeleteUserCheckboxChange(userID) {
    let usersToDelete = [...this.state.usersToDelete];
    let checked = document.getElementById("removeUser-"+userID).checked;
    if(checked) {
      usersToDelete.push(userID);
      this.setState({ usersToDelete: usersToDelete });
    } else {
      let newUsersToDelete = usersToDelete.filter((id) => { return !(id === userID) });
      this.setState({ usersToDelete: newUsersToDelete });
    }
  }

  handleAddUserCheckboxChange(userID) {
    let checked = document.getElementById("addUser-"+userID).checked;
    let usersToAdd = [...this.state.usersToAdd];
    if(checked) {
      usersToAdd.push(userID);
      this.setState({ usersToAdd: usersToAdd });
    } else {
      let newUsersToAdd = usersToAdd.filter((id) => { return !(id === userID) });
      this.setState({ usersToAdd: newUsersToAdd });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleRangeChange = (value) => {
    const validityParams = {
      startDate: value.start,
      endDate: value.end,
    };
    this.setState({ validityParams: validityParams});
  }

  handleAddRole() {
    let groupRoles = [...this.state.groupRoles];
    this.state.rolesToAdd.forEach(role => {
      let newRole = this.state.roles.find((grole) => grole.roleID === role);
      let newGRole = {};
      newGRole.roleID = newRole.roleID;
      newGRole.name = newRole.roleName;
      newGRole.description = newRole.roleDescription;
      groupRoles.push(newGRole);
      this.setState({
        groupRoles: groupRoles,
      });
    });

    this.setState({rolesToAdd: []});
  }

  handleRemoveRole() {
    let groupRoles = [...this.state.groupRoles];
    this.state.rolesToDelete.forEach(role => {
      groupRoles = groupRoles.filter((grole) => grole.roleID !== role);
      this.setState({
        groupRoles: groupRoles,
      });
    });
    this.setState({rolesToDelete: []});
  }

  handleAddUser() {
    let groupUsers = [...this.state.groupUsers];
    this.state.usersToAdd.forEach(user => {
      let newUser = this.state.users.find((guser) => guser.userID === user);
      groupUsers.push(newUser);
      this.setState({
        groupUsers: groupUsers,
      });
    });

    this.setState({usersToAdd: []});
  }

  handleRemoveUser() {
    let groupUsers = [...this.state.groupUsers];
    this.state.usersToDelete.forEach(user => {
      groupUsers = groupUsers.filter((guser) => guser.userID !== user);
      this.setState({
        groupUsers: groupUsers,
      });
    });
    this.setState({usersToDelete: []});
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleNavCancel = () => {
    this.setState({
      showConfirmNavModal: false,
      newPage: '' });
  }

  handleNavConfirm = (newPage) => {
    this.props.history.push({pathname: newPage});
  }

  handleHideUpdatedModal = () => {
    this.setState({ showUpdatedModal: false });
  }

  handleBreadcrumbClick = (newPage, event) => {
    if (this.validateForm()) {
      this.setState({
        showConfirmNavModal: true,
        newPage: newPage
      });
    } else {
      this.props.history.push({pathname: newPage});
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });
    let self = this;

    try {
      let group = self.state.group;
      self.setState({ group: group });

      let rolesToAdd = self.state.groupRoles.filter((role) => {
          return self.state.origGroupRoles.findIndex((orole) => role.roleID === orole.roleID) === -1;
      });
      await async.each(rolesToAdd, async (role) => {
        await addRoleToGroup(self.state.group.groupID, role.roleID);
      }, (e) => {
        if( e ) {
          self.setState({
            alertMessage: "Error adding roles to group: " + e.message,
            showModal: true,
            isLoading: false
          });
          throw(e);
        }
      });
      rolesToAdd.forEach((role) => {
        role.addedByName = self.state.user.user.firstName + ' ' + self.state.user.user.lastName;
        role.addedOn = new Date();
        self.state.origGroupRoles.push(role);
        self.state.groupRoles.map((grole) => {
          if (grole.roleID === role.roleID) {
            return role;
          } else {
            return grole;
          }
        });
      });

      let rolesToRemove = self.state.origGroupRoles.filter((role) => {
          return self.state.groupRoles.findIndex((orole) => role.roleID === orole.roleID) === -1;
      });
      await async.each(rolesToRemove, async (role) => {
        await removeRoleFromGroup(self.state.group.groupID, role.roleID);
      }, (e) => {
        if( e ) {
          self.setState({
            alertMessage: "Error removing roles from group: " + e.message,
            showModal: true,
            isLoading: false
          });
          throw(e);
       }
      });
      self.state.origGroupRoles = self.state.origGroupRoles.filter((role) => {
          return self.state.groupRoles.findIndex((orole) => role.roleID === orole.roleID) !== -1;
      });

      let usersToAdd = self.state.groupUsers.filter((user) => {
          return self.state.origGroupUsers.findIndex((ouser) => user.userID === ouser.userID) === -1;
      });
      async.each(usersToAdd, async (user) => {
        await addUserToGroup(self.state.user.userType, self.state.group.groupID, user.userID);
      }, (e) => {
        if( e ) {
          self.setState({
            alertMessage: "Error adding users to group: " + e.message,
            showModal: true,
            isLoading: false
          });
          throw(e);
       }
      });
      usersToAdd.forEach((user) => {
        user.addedByName = self.state.user.user.firstName + ' ' + self.state.user.user.lastName;
        user.addedOn = new Date();
        self.state.origGroupUsers.push(user);
        self.state.groupUsers.map((guser) => {
          if (guser.userID === user.userID) {
            return user;
          } else {
            return guser;
          }
        });
      });


      let usersToRemove = self.state.origGroupUsers.filter((user) => {
        return self.state.groupUsers.findIndex((ouser) => user.userID === ouser.userID) === -1;
      });
      async.each(usersToRemove, async (user) => {
        await removeUserFromGroup(self.state.user.userType, self.state.group.groupID, user.userID);
      }, (e) => {
        if( e ) {
          self.setState({
            alertMessage: "Error removing roles from group: " + e.message,
            showModal: true,
            isLoading: false
          });
          throw(e);
        }
      });
      self.state.origGroupUsers = self.state.origGroupUsers.filter((user) => {
          return self.state.groupUsers.findIndex((ouser) => user.userID === ouser.userID) !== -1;
      });

      if ((self.state.groupName.length > 0 &&
        self.state.groupName !== self.state.group.groupName) ||
        self.state.groupState !== self.state.group.groupState ||
        self.state.validityCriteria !== self.state.group.validityCriteria ||
        (self.state.validityCriteria === "TIME" &&
         self.state.validityParams && (
         !self.state.group.validityParams ||
         self.state.validityParams.startDate !== self.state.group.validityParams.startDate ||
         self.state.validityParams.endDate !== self.state.group.validityParams.endDate))) {

        let result;
        if (self.state.validityCriteria !== self.state.group.validityCriteria ||
            (self.state.validityCriteria === "TIME" &&
             self.state.validityParams && (
             !self.state.group.validityParams ||
             self.state.validityParams.startDate !== self.state.group.validityParams.startDate ||
             self.state.validityParams.endDate !== self.state.group.validityParams.endDate))) {
          result = await updateGroupInfo(self.state.group.groupID,
                              self.state.groupName,
                              self.state.groupState,
                              self.state.validityCriteria,
                              self.state.validityParams);
        } else {
          result = await updateGroupInfo(self.state.group.groupID,
                              self.state.groupName,
                              self.state.groupState);
        }
        if (result.error) {
          throw(new Error(result.error));
        }
      }
    } catch (e) {
      self.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
      throw(e);
    }
    self.state.group.groupName = self.state.groupName;
    self.state.group.groupState = self.state.groupState;
    self.state.group.validityCriteria = self.state.validityCriteria;
    self.state.group.validityParams = self.state.validityParams;

    self.setState({
      showUpdatedModal: true,
      isLoading: false
    });
  }

  handleRevert = async event => {

    this.setState({ revertIsLoading: true });

    this.setState({
      groupRoles: this.state.origGroupRoles,
      groupUsers: this.state.origGroupUsers,
      groupName: this.state.group.groupName,
      validityCriteria: this.state.group.validityCriteria,
      validityParams: this.state.group.validityParams,
      revertIsLoading: false
    });
  }

  render() {
    const navbarData = navbarSetup(this.state);
    const startingTab = this.props.location.state.tab;

    const columnDefs = [];
    columnDefs.push(
      { Header: "Name",
        accessor: "name",
        minWidth: 150 }
    );
    columnDefs.push(
      { Header: "Description",
        id: "description",
        accessor: "description",
        minWidth: 150 }
    );
    columnDefs.push(
      { Header: "Added By",
        id: "addedByName",
        accessor: "addedByName",
        minWidth: 100 }
    );
    columnDefs.push(
      { Header: "Added On",
        id: "addedOn",
        accessor: "addedOn",
        Cell: (row) => {
          return (
            <div>
              {row.original.addedOn ?
                moment(row.original.addedOn).format('ll')
              :
                ''
              }
            </div>
          )
        },
        minWidth: 100 }
    );
   columnDefs.push(
      { Header: "",
        id: "roleID",
        accessor: "roleID",
        minWidth: 40,
        maxWidth: 40,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <Checkbox
                type="checkbox"
                id={"remove-"+row.original.roleID}
                checked={this.state.rolesToDelete.filter((role) => role === row.original.roleID)[0]}
                onChange={this.handleDeleteCheckboxChange.bind(this, row.original.roleID)}
              />
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let groupRoles = this.state && this.state.groupRoles && this.state.groupRoles.length > 0 ?
      this.state.groupRoles
      : [];

    const roleColDefs = [];
    roleColDefs.push(
      { Header: "Name",
        accessor: "roleName",
        minWidth: 100 }
    );
    roleColDefs.push(
      { Header: "Description",
        id: "roleDescription",
        accessor: "roleDescription",
        minWidth: 150 }
    );
   roleColDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "roleID",
        accessor: "roleID",
        minWidth: 40,
        maxWidth: 40,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <Checkbox
                type="checkbox"
                id={"add-"+row.original.roleID}
                checked={this.state.rolesToAdd.filter((role) => role === row.original.roleID)[0]}
                onChange={this.handleAddCheckboxChange.bind(this, row.original.roleID)}
              />
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let roles = this.state.roles && this.state.roles.length > 0 ? this.state.roles : [];
    let filteredRoles = roles.filter(role => groupRoles.findIndex(grole => grole.roleID === role.roleID) === -1);


    // Users tables
    const groupUsersColDefs = [];
    groupUsersColDefs.push(
      { Header: "Name",
        id: "userID",
        accessor: "userID",
        Cell: (row) => {
          return (
            <div>
              {row.original.firstName + ' ' + row.original.lastName}
            </div>
          );
        },
        sortMethod: (a, b) => {
          let userA = this.state.users ? this.state.users.find((user) => user.userID === a) : {};
          let userB = this.state.users ? this.state.users.find((user) => user.userID === b) : {};
          let userNameA = userA.firstName+' '+userA.lastName;
          let userNameB = userB.firstName+' '+userB.lastName;
          return userNameA > userNameB ? 1 : -1;
          },
        filterMethod: (filter, row) => {
          let filterRegex = new RegExp(filter.value, 'i');
          return filterRegex.test(row._original.firstName) ||
                 filterRegex.test(row._original.lastName);
        },
        minWidth: 150 }
    );
    groupUsersColDefs.push(
      { Header: "Username",
        id: "userName",
        accessor: "userName",
        minWidth: 100 }
    );
    groupUsersColDefs.push(
      { Header: "Added On",
        id: "addedOn",
        accessor: "addedOn",
        Cell: (row) => {
          return (
            <div>
              {moment(row.original.addedOn).format('ll')}
            </div>
          )
        },
        minWidth: 100 }
    );
   groupUsersColDefs.push(
      { Header: "Type",
        id: "userType",
        accessor: "userType",
        minWidth: 100,
        maxWidth: 100,
        className: "text-center",
        sortable: true,
        filterable: true,
      }
    );
    groupUsersColDefs.push(
      { Header: "",
        id: "name",
        accessor: "name",
        minWidth: 40,
        maxWidth: 40,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <Checkbox
                type="checkbox"
                id={"removeUser-"+row.original.userID}
                checked={this.state.usersToDelete.filter((user) => user === row.original.userID)[0]}
                onChange={this.handleDeleteUserCheckboxChange.bind(this, row.original.userID)}
              />
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let groupUsers = this.state && this.state.groupUsers && this.state.groupUsers.length > 0 ?
      this.state.groupUsers
      : [];

    const userColDefs = [];
    userColDefs.push(
      { Header: "Name",
        id: "userID",
        accessor: "userID",
        Cell: (row) => {
          return (
            <div>
              {row.original.firstName + ' ' + row.original.lastName}
            </div>
          );
        },
        sortMethod: (a, b) => {
          let userA = this.state.users ? this.state.users.find((user) => user.userID === a) : {};
          let userB = this.state.users ? this.state.users.find((user) => user.userID === b) : {};
          let userNameA = userA.firstName+' '+userA.lastName;
          let userNameB = userB.firstName+' '+userB.lastName;
          return userNameA > userNameB ? 1 : -1;
          },
        filterMethod: (filter, row) => {
          let filterRegex = new RegExp(filter.value, 'i');
          return filterRegex.test(row._original.firstName) ||
                 filterRegex.test(row._original.lastName);
        },
        minWidth: 150 }
    );
    userColDefs.push(
      { Header: "Username",
        id: "userName",
        accessor: "userName",
        minWidth: 100 }
    );
    userColDefs.push(
      { Header: "Type",
        id: "userType",
        accessor: "userType",
        minWidth: 100 }
    );
    userColDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "name",
        accessor: "name",
        minWidth: 40,
        maxWidth: 40,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <Checkbox
                type="checkbox"
                id={"addUser-"+row.original.userID}
                checked={this.state.usersToAdd.filter((user) => user === row.original.userID)[0]}
                onChange={this.handleAddUserCheckboxChange.bind(this, row.original.userID)}
              />
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let users = this.state.users && this.state.users.length > 0 ? this.state.users.filter((u) => u.userState === "ACTIVE") : [];
    let filteredUsers = users.filter(user => groupUsers.findIndex(guser => guser.userID === user.userID) === -1);
    let startDate = this.state.validityParams && this.state.validityParams.startDate ? this.state.validityParams.startDate : moment.utc().startOf('day');
    let endDate = this.state.validityParams && this.state.validityParams.endDate ? this.state.validityParams.endDate : moment.utc().endOf('day');

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} handleClick={this.handleBreadcrumbClick.bind(this)}/>
        <div className="panel-frame">

          {this.state.group ?
          <div>
            <div className="col-sm-12">
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="row">
                  <ControlLabel className="col-sm-2 right-align">Group Name:</ControlLabel>
                  { this.state.user.userType === "ADMIN" ?
                    <FormGroup className="col-sm-4" controlId="groupName" bsSize="large">
                      <FormControl
                          autoFocus
                          className={this.state.verifyGroupName ? 'error' : ''}
                          type="text"
                          maxLength="100"
                          placeholder="Group Name"
                          value={this.state.groupName.length > 0 ? this.state.groupName : ""}
                          onChange={this.handleChange}
                      />
                      { this.state.verifyGroupName && this.state.groupName.length === 0 ?
                        <HelpBlock className="error">Group Name cannot be blank</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                  :
                    <ControlLabel className="col-sm-4">{this.state.groupName.length > 0 ? this.state.groupName : ""}</ControlLabel>
                  }
                  <FormGroup className="col-sm-3" bsSize="large">
                    <div className="col-sm-11">
                      <LoaderButton
                        block
                        className="red-button"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save Group"
                        loadingText="Updating group…"
                        onClick={this.handleSubmit.bind(this)}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="col-sm-3" bsSize="large">
                    <div className="col-sm-11">
                      <LoaderButton
                        block
                        className="red-button"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        isLoading={this.state.revertIsLoading}
                        text="Revert to Saved"
                        loadingText="Reverting to saved…"
                        onClick={this.handleRevert.bind(this)}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className="row">
                  <ControlLabel className="col-sm-2 right-align">State:</ControlLabel>
                  { this.state.user.userType === "ADMIN" ?
                    <div className="col-sm-4 left-align">
                      <select
                        value={this.state.groupState}
                        className="col-sm-6"
                        onChange={this.handleChange.bind(this)}
                        id="groupState" >
                        <option key="1" value="ACTIVE">ACTIVE</option>
                        <option key="2" value="INACTIVE">INACTIVE</option>
                      </select>
                    </div>
                  :
                    <ControlLabel className="col-sm-4 margin-bottom-15">{this.state.groupState}</ControlLabel>
                  }
                </div>
                <div className="row">
                  <ControlLabel className="col-sm-2 right-align">Validity Criteria:</ControlLabel>
                  { this.state.user.userType === "ADMIN" ?
                    <div className="col-sm-4 left-align">
                      <select
                        value={this.state.validityCriteria}
                        className="col-sm-6"
                        onChange={this.handleChange.bind(this)}
                        id="validityCriteria" >
                        <option key="1" value="NORMAL">NORMAL</option>
                        <option key="2" value="TIME">TIME</option>
                      </select>
                    </div>
                  :
                    <ControlLabel className="col-sm-4 margin-bottom-15">{this.state.validityCriteria}</ControlLabel>
                  }
                </div>
                { this.state.validityCriteria === "TIME" ?
                  <div className="row">
                    <ControlLabel className="col-sm-2 right-align">Start/End Time:</ControlLabel>
                    { this.state.user.userType === "ADMIN" ?
                      <div className="col-sm-4 left-align">
                        <CustomDatePicker
                          value={ { start: startDate, end: endDate, name: "Custom" } }
                          onChange={this.handleRangeChange.bind(this)}
                        />
                      </div>
                    :
                      <div className="col-sm-6 margin-bottom-15">
                        <ControlLabel>{this.state.validityParams ? this.state.validityParams.startDateStr : '<not set>'}</ControlLabel>
                        <ControlLabel>{this.state.validityParams ? this.state.validityParams.endDateStr : '<not set>'}</ControlLabel>
                      </div>
                    }
                  </div>
                  : ''
                  }
              </form>
            </div>

            <Tabs defaultActiveKey={startingTab === 'editGroup' ? 1 : 2} id="group-management-tabs">
              { this.state.user.userType === "ADMIN" ?
              <Tab key="1" eventKey={1} title="Edit Roles">
                <TabContent>
                  { this.state.roles ?
                  <div className={this.state.windowWidth > 400 ? "col-md-12" : "col-md-12 no-left-padding"} >
                    <div></div>
                    <h3 className="col-sm-7 text-center">Group Roles</h3>
                    <div className="col-sm-1"></div>
                    <h3 className="col-sm-4 text-center">Roles</h3>

                    <div className="auto-auth-table col-sm-7">
                      <ReactTable
                        columns={columnDefs}
                        data={groupRoles}
                        className="-highlight"
                        defaultPageSize={10}
                        showPagination={true}
                        filterable={false}
                        defaultSorted={[
                          {
                            id: "name",
                            desc: false
                          }
                        ]}
                      />
                    </div>
                    <div className="col-sm-1 text-center">
                      <div>
                        <Button bsStyle="link" href="#" onClick={this.handleAddRole.bind(this)}>
                          <Icon title="Add Role to Group" name="arrow left" size='large' />
                        </Button>
                      </div>
                      <div>
                        <Button bsStyle="link" href="#" onClick={this.handleRemoveRole.bind(this)}>
                          <Icon title="Remove Role from Group" name="arrow right" size='large' />
                        </Button>
                      </div>
                    </div>
                    <div className="auto-auth-table col-sm-4">
                      <ReactTable
                        columns={roleColDefs}
                        data={filteredRoles}
                        className="-highlight"
                        defaultPageSize={10}
                        showPagination={true}
                        filterable={true}
                        defaultSorted={[
                          {
                            id: "name",
                            desc: false
                          }
                        ]}
                      />
                    </div>
                  </div>
                  :
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                  }
                </TabContent>
              </Tab>
              : ""
              }
              <Tab key="2" eventKey={2} title="Edit Users">
                <TabContent>
                  { this.state.users ?
                  <div className={this.state.windowWidth > 400 ? "col-md-12" : "col-md-12 no-left-padding"} >
                    <div></div>
                    <h3 className="col-sm-7 text-center">Group Users</h3>
                    <div className="col-sm-1"></div>
                    <h3 className="col-sm-4 text-center">Users</h3>

                    <div className="auto-auth-table col-sm-7">
                      <ReactTable
                        columns={groupUsersColDefs}
                        data={groupUsers}
                        className="-highlight"
                        defaultPageSize={10}
                        showPagination={true}
                        filterable={true}
                        defaultSorted={[
                          {
                            id: "userID",
                            desc: false
                          }
                        ]}
                      />
                    </div>
                    <div className="col-sm-1 text-center">
                      <div>
                        <Button bsStyle="link" href="#" onClick={this.handleAddUser.bind(this)}>
                          <Icon title="Add User to Group" name="arrow left" size='large' />
                        </Button>
                      </div>
                      <div>
                        <Button bsStyle="link" href="#" onClick={this.handleRemoveUser.bind(this)}>
                          <Icon title="Remove User from Group" name="arrow right" size='large' />
                        </Button>
                      </div>
                    </div>
                    <div className="auto-auth-table col-sm-4">
                      <ReactTable
                        columns={userColDefs}
                        data={filteredUsers}
                        className="-highlight"
                        defaultPageSize={10}
                        showPagination={true}
                        filterable={true}
                        defaultSorted={[
                          {
                            id: "userID",
                            desc: false
                          }
                        ]}
                      />
                    </div>
                  </div>
                  :
                  <div className="loading-panel">
                    <Glyphicon glyph="repeat" className="spinning" />
                  </div>
                  }
                </TabContent>
              </Tab>
            </Tabs>
          </div>
          :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
          }
        </div>

        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>

        <Modal show={this.state.showUpdatedModal} onHide={this.handleHideUpdatedModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Group Updated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Group, {this.state.groupName} has been successfully updated.</p>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showConfirmNavModal} onHide={this.handleNavCancel.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Group Updated</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have unsaved changes that will be lost if you navigate away.  Do you want to continue?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleNavConfirm.bind(this, this.state.newPage)}>Yes, Ignore Changes</Button>
            <Button onClick={this.handleNavCancel.bind(this)}>No, Go Back</Button>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }
}
