import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { Breadcrumb } from 'semantic-ui-react';
import { breadcrumbNameMap } from '../Routes';

export default class AutoAuthBreadcrumbs extends Component {

  handleClick = (newPage, event) => {
    if (this.props.handleClick) {
      event.preventDefault();
      this.props.handleClick(newPage);
    }
  }
  
  render() {
    const pathnames = this.props.pathname.split('/').filter(x => x);

    let paths = [];
    paths[0] = {
      key: "home",
      content: (this.props.handleClick ? 'Home' : (<NavLink to='/home'>Home</NavLink>)),
      onClick: (this.props.handleClick ? this.handleClick.bind(this, '/home') : null)
    }
    pathnames.forEach((value, index) => {
      const last = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join('/')}`;
      
      if (last) {
        paths.push({
          key: index,
          content: breadcrumbNameMap[value].label,
          active: true,
        });
      } else {
        paths.push({
          key: index,
          content: (this.props.handleClick ? breadcrumbNameMap[value].label : (<NavLink to={to}>{breadcrumbNameMap[value].label}</NavLink>)),
          onClick: (this.props.handleClick ? this.handleClick.bind(this, to) : null)
       });
      }
    });

    return (
      <Breadcrumb icon='right chevron' sections={paths} aria-label="breadcrumb">
      </Breadcrumb>
    );
  }
}