import React, { Component } from "react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import { navbarSetup, resetUserData, systemInfo } from "../libs/utils";
import AlertModal from "../components/AlertModal";
import "./Support.css";
import {
  Glyphicon,
  Row,
  Col
} from "react-bootstrap";

export default class SystemInfo extends Component {
  
  constructor(props) {
    super(props);

    let sysInfo = systemInfo();

    this.state = {
      user: props.user,
      isLoading: true,
      browserName: sysInfo.browserName,
      fullVersion: sysInfo.browserVersion,
      hostOS: sysInfo.hostOS
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) { 
      return;
    }
    try {
      await resetUserData(this, true);
      if (this.state.dataRetrieveError) {
        this.setState({
          alertMessage: this.state.dataRetrieveError,
          showModal: true,
          isLoading: false
        });
      } else {
        this.props.updateUserInfo(this.state.user);
        this.setState({isLoading: false});
      }
    } catch (e) {
      console.log("Error getting User Info: ", e);
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  
  handleCancel = () => {
    this.setState({ showModal: false });
  }
  
  renderLander() {

    const navbarData = navbarSetup(this.state);
    
    let shops = this.state && this.state.user && this.state.user.shops ?
      this.state.user.shops.map((shop) => {
        return {
          shopID: shop.shopID,
          name: shop.shopName,
          role: shop.shopUserRole,
          state: shop.shopUserState
        }
      })
      : [];
    
    return (
      <div>
        { this.state && this.state.user ?
        <div key="menubar" className="home">
          <AutoAuthNavBar 
            manufacturer={navbarData.thisIsAManufacturer} 
            serviceCenter={navbarData.thisIsAServiceCenter} 
            name={navbarData.name} 
            shop={navbarData.shop} 
            shops={shops}
            shopRole={navbarData.shopRole} 
            shopState={navbarData.shopState} 
            emailVerified={navbarData.emailVerified} 
            userHasAuthenticated={this.props.userHasAuthenticated}
            pathname={this.props.location.pathname}>
          </AutoAuthNavBar>
          <Row key="welcome" className="panel welcome">
            <div>System Information</div>
          </Row>
          
        </div>
        : this.state.dataRetrieveError ?
        null 
        :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
        }
        <Row key="systemInfo" >
          <Col md={4}></Col>
          <Col key="infoTable" className="support-info" md={6}>
            <div className="col-md-3">Browser:</div><div className="col-md-7">{this.state.browserName} {this.state.fullVersion}</div>
            <div className="col-md-3">Host OS:</div><div className="col-md-7">{this.state.hostOS}</div>
          </Col>
          <Col></Col>
        </Row>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }

  renderNotAuthenticated() {

    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): ''}
      </div>
    );
  }
}
