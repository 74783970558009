import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import AlertModal from "../components/AlertModal";
import LoaderButton from "../components/LoaderButton";
import { recordUserSession, engBridgeLogin, getUserRecord } from "../libs/db-lib";
import { fieldChangeCase } from "../libs/utils";

import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      alertMessage: '',
      showModal: false
    };
  }

  componentDidMount() {
    // Check the username and password fields to see is values have been autofilled
    let username = document.getElementById('username');
    if (username.length > 0) {
      this.setState({
        username: username
      });
    }
    let password = document.getElementById('password');
    if (password.length > 0) {
      this.setState({
        password: password
      })
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, "lower");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }
  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleChangePage = (path, event) => {
    event.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(user =>  {
        Auth.signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push("/"+path);
      })
      .catch(err => {
        this.props.history.push("/"+path);
      });
  }

  handleRequestAccount = event => {
    event.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(user =>  {
        Auth.signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push("/requestAccount");
      })
      .catch(err => {
        this.props.history.push("/requestAccount");
      });
  }


  handleResetPassword = event => {
    event.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(user =>  {
        Auth.signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push("/resetPassword");
      })
      .catch(err => {
        this.props.history.push("/resetPassword");
      });
  }

  handleResendConfirm = event => {
    event.preventDefault();

    Auth.currentAuthenticatedUser()
      .then(user =>  {
        Auth.signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push("/resendConfirmCode");
      })
      .catch(err => {
        this.props.history.push("/resendConfirmCode");
      });
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true, signingIn: true });

    let result = await engBridgeLogin(this.state.username, this.state.password);

    if (result) {
      let errMsg = result;
      if (errMsg === "User does not exist.") {
        errMsg = "Incorrect Username or Password.";
      } else if (errMsg === "User is not confirmed.") {
        errMsg = "User is not confirmed.  Please check your email for your account validation link.";
      }
      this.setState({
        alertMessage: errMsg,
        showModal: true,
        isLoading: false
      });
    } else {
      const userRec = await getUserRecord();
      if (userRec.error) {
        this.setState({
          alertMessage: "Couldn't get user information: " + userRec.error,
          showModal: true,
          isLoading: false
        });
      } else {
        if (userRec.authProvider === "COGNITO") {
          Auth.signIn(this.state.username, this.state.password);
        }
        recordUserSession();
        this.props.userHasAuthenticated(true);
        this.setState({signingIn: true});
        this.props.history.push("/Home");
      }
    }
  }

  render() {
    return (
      <div>
        <div className="Login col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 white-container">
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="username" bsSize="large">
              <ControlLabel className={this.state.usernameFieldActivate || this.state.username.length > 0 ? "float-label field-active" : "float-label"}>Username or Email Address</ControlLabel>
              <FormControl
                autoFocus
                className="floating-label"
                maxLength="100"
                type="text"
                value={this.state.username}
                onChange={this.handleChange}
                autoCapitalize="off"
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <ControlLabel className={this.state.passwordFieldActivate || this.state.password.length > 0 ? "float-label field-active" : "float-label"}>Password</ControlLabel>
              <FormControl
                maxLength="50"
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
            </FormGroup>
            <FormGroup>
              <LoaderButton
                className="red-button lg-btn"
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Login"
                loadingText="Logging in…"
              />
            </FormGroup>
            <div>
              <Link to="/resetPassword" onClick={this.handleResetPassword.bind(this)}>
                Forgot Password?
              </Link>
            </div>
            <div>
              <Link to="/resendConfirmCode" onClick={this.handleResendConfirm.bind(this)}>
                Resend Confirm Link?
              </Link>
            </div>
          </form>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }
}
