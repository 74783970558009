import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import "./Welcome.css";

export default class Welcome extends Component {

  getServiceCenter() {
    return API.get("echo-dev", "/getCenter", {response: true});
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      const serviceCenter = await this.getServiceCenter();
      console.log("serviceCenter:", serviceCenter.data);
      this.state = {
        isLoading: true,
        serviceCenter: serviceCenter
      };
      if (serviceCenter.data && serviceCenter.data.length > 0) {
        const record = serviceCenter.data[0];
        this.setState({'firstName': record.content.firstName,
                       'lastName': record.content.lastName,
                       'company': record.content.company,
                       'email': record.content.email});
        this.props.updateUserInfo({'firstName': record.content.firstName,
                                   'lastName': record.content.lastName,
                                   'company': record.content.company,
                                   'email': record.content.email});
      }
    } catch (e) {
      console.log(e);
      alert(e);
    }
  }
  
  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  }
  
  renderLander() {
  
    console.log(this.state);
    
    return (
      <div className="home">
        <div className="panel welcome">
          <div className="center">Welcome to AutoAuth!</div>
          <div className="center">Click <Link to="/">here</Link> to get started</div>
        </div>
      </div>
    );
  }

  renderNotAuthenticated() {
  
    console.log(this.state);

    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): this.renderNotAuthenticated}
      </div>
    );
  }
}
