import React, { Component } from 'react';
import {
  InputGroup,
} from "react-bootstrap";

export default class RolePicker extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentRole: '',
    }
  }

  componentDidMount() {
    this.setState({
      currentRole: this.props.activeRole,
    });
  }

  handleChange(event) {
    // Change the tools List
    let newRole = event.target.value;

    let roleRec = this.props.roles.find(role => role.roleID === newRole);

    this.setState({
      currentRole: roleRec ? roleRec.name : ''
    });

    if (this.props.handleRoleChange) {
      this.props.handleRoleChange(roleRec ? roleRec.roleID : '');
    }
  }

  render() {

    let rolesOptions = [];
    let sortedRoles = this.props.roles.sort((role1, role2) => { return role1.roleName > role2.roleName ? 1 : -1 });
    rolesOptions.push(
      <option key="-1" value="0">No Role</option>
    );
    rolesOptions.push(
      sortedRoles.map((role, i) => {
        return <option key={i} value={role.roleID}>{role.roleName}</option>
      })
    );

    return (
      <InputGroup className="select-container col-sm-12">
          <select
            value={this.props.activeRole}
            className="col-sm-12"
            onChange={this.handleChange.bind(this)}
            id="activeRole" >
            {rolesOptions}
          </select>
      </InputGroup>
    )
  };
}
