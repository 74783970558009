import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import ResendConfirmCode from "./containers/ResendConfirmCode";
import Signup from "./containers/Signup";
import UserCreate from "./containers/UserCreate";
import UserProfile from "./containers/UserProfile";
import ManageUsers from "./containers/ManageUsers";
import EditUser from "./containers/EditUser";
import ToolManufacturerCreate from "./containers/ToolManufacturerCreate";
import ToolManufacturerSignup from "./containers/ToolManufacturerSignup";
import Home from "./containers/Home";
import Support from "./containers/Support";
import SystemInfo from "./containers/SystemInfo";
import UserFAQ from "./containers/UserFAQ";
import FAQ from "./containers/FAQ";
import ContactUs from "./containers/ContactUs";
import AboutUs from "./containers/AboutUs";
import Welcome from "./containers/Welcome";
import ManageTools from "./containers/ManageTools";
import ManageGroups from "./containers/ManageGroups";
import EditGroup from "./containers/EditGroup";
import NotFound from "./containers/NotFound";
import OEMSignup from "./containers/OEMSignup";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/resetPassword" exact component={ResetPassword} props={childProps} />
    <UnauthenticatedRoute path="/resendConfirmCode" exact component={ResendConfirmCode} props={childProps} />
    <UnauthenticatedRoute path="/toolManufacturerSignup" exact component={ToolManufacturerSignup} props={childProps} />
    <UnauthenticatedRoute path="/OEMSignup" exact component={OEMSignup} props={childProps} />
    <UnauthenticatedRoute path="/requestAccount" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/FAQ" exact component={FAQ} props={childProps} />
    <UnauthenticatedRoute path="/ContactUs" exact component={ContactUs} props={childProps} />
    <UnauthenticatedRoute path="/AboutUs" exact component={AboutUs} props={childProps} />
    <AuthenticatedRoute path="/userCreate" exact component={UserCreate} props={childProps} />
    <AuthenticatedRoute path="/userProfile" exact component={UserProfile} props={childProps} />
    <AuthenticatedRoute path="/manageGroups" exact component={ManageGroups} props={childProps} />
    <AuthenticatedRoute path="/manageGroups/editGroup" exact component={EditGroup} props={childProps} />
    <AuthenticatedRoute path="/manageUsers" exact component={ManageUsers} props={childProps} />
    <AuthenticatedRoute path="/manageUsers/editUser" exact component={EditUser} props={childProps} />
    <AuthenticatedRoute path="/toolManufacturerCreate" exact component={ToolManufacturerCreate} props={childProps} />
    <AuthenticatedRoute path="/welcome" exact component={Welcome} props={childProps} />
    <AuthenticatedRoute path="/manageTools" exact component={ManageTools} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/Support" exact component={Support} props={childProps} />
    <AuthenticatedRoute path="/SystemInfo" exact component={SystemInfo} props={childProps} />
    <AuthenticatedRoute path="/UserFAQ" exact component={UserFAQ} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;

  export const breadcrumbNameMap = {
    "resetPassword": { label: "Reset Password", link: true },
    "resendConfirmCode": { label: "Resend Confirm Code", link: true },
    "register": { label: "Register", link: true },
    "requestAccount": { label: "Request Account", link: true },
    "userCreate": { label: "User Create", link: true },
    "addGroup": { label: "Add Group", link: true },
    "userProfile": { label: "User Profile", link: true },
    "manageGroups": { label: "Manage Groups", link: true },
    "manageTools": { label: "Manage Tools", link: true },
    "editGroup": { label: "Edit Group", link: true },
    "manageUsers": { label: "Manage Users", link: true },
    "editUser": { label: "Edit User", link: true },
    "Home": { label: "Home", link: true },
    "Support": { label: "Support", link: true },
  }
