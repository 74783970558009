import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import Recaptcha from 'react-recaptcha';
import LoaderButton from "../components/LoaderButton";
import "./Signup.css";
import AutoAuthUnauthNavBar from "./AutoAuthUnauthNavBar";
import { fieldChangeCase } from "../libs/utils";

export default class ToolManufacturerSignup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isVerified: false,
      company: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null
    };

    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  passwordValidate(password) {
    var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&s*])(?=.{8,})");
    return strongRegex.test(password);
  }
  
  usernameValidate(username) {
    var usernameRegex = new RegExp("^([a-zA-Z]{1})([a-zA-Z0-9]{7,})");
    return usernameRegex.test(username);
  }
  
  validateForm() {
    let passwordValid = this.passwordValidate(this.state.password);
    let usernameValid = this.usernameValidate(this.state.username);
    return (
      this.state.username.length > 0 &&
      this.state.email.length > 0 &&
      this.state.company.length > 0 &&
      this.state.address.length > 0 &&
      this.state.city.length > 0 &&
      this.state.state.length > 0 &&
      this.state.zip.length > 0 &&
      this.state.password.length > 0 &&
      passwordValid &&
      usernameValid &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }
  
  verifyCaptcha(response) {
    if (response) {
      this.setState({'isVerified': true});
    }
  }
  
  handleChange = event => {
    if (event.target.id === 'username') { 
      fieldChangeCase(this, event.target, "lower");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.state.isVerified) {
      this.setState({ isLoading: true });
  
      try {
        const newUser = await Auth.signUp({
          username: this.state.username,
          password: this.state.password,
          attributes: {
            email: this.state.email,
            given_name: this.state.firstName,
            family_name: this.state.lastName
          }
        });
        this.setState({
          newUser
        });
      } catch (e) {
        alert(e.message);
      }
  
      this.setState({ isLoading: false });
    } else {
      alert("Please prove you are human!")
    }
  }

  handleConfirmationSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(this.state.username, this.state.confirmationCode);
      await Auth.signIn(this.state.username, this.state.password);

      var toolManufacturer = {
        company: this.state.company,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email
      };
      this.props.userHasAuthenticated(true);
      this.props.history.push("/toolManufacturerCreate", toolManufacturer);
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  }

  renderConfirmationForm() {
    return (
      <form onSubmit={this.handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>Confirmation Code</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            value={this.state.confirmationCode}
            onChange={this.handleChange}
          />
          <HelpBlock>Please check your email for the code.</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          disabled={!this.validateConfirmationForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Verify"
          loadingText="Verifying…"
        />
      </form>
    );
  }

  renderForm() {
    return (
      <form className="white-container" onSubmit={this.handleSubmit}>
        <FormGroup>
          <h2 className="text-center">Tool Vendor Signup</h2>
        </FormGroup>
        <FormGroup controlId="username" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Username"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="company" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Company Name"
            value={this.state.company}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="address" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Street Address"
            value={this.state.address}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="city" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="City"
            value={this.state.city}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="state" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="State"
            value={this.state.state}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="zip" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Zip Code"
            value={this.state.zip}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <h3 className="text-center">Primary Contact</h3>
        </FormGroup>
        <FormGroup controlId="firstName" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="First Name"
            value={this.state.firstName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="lastName" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Last Name"
            value={this.state.lastName}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="phone" bsSize="large">
          <FormControl
            autoFocus
            type="text"
            placeholder="Phone Number"
            value={this.state.phone}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="large">
          <FormControl
            autoFocus
            type="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormControl
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <FormControl
            placeholder="Confirm Password"
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        
        <div className="btn-container">
          <LoaderButton
            className="red-button"
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Signup"
            loadingText="Signing up…"
          />
        </div>
        <div className="captcha-container">
          <Recaptcha
            sitekey="6LfeyGYUAAAAAJpc5G-X4DUiMjd1kw081WmKtMNZ"
            render="explicit"
            verifyCallback={this.verifyCaptcha}
          />
        </div>
      </form>
    );
  }

  // render() {
  //   return (
  //     <div className="Signup">
  //       {this.state.newUser === null
  //         ? this.renderForm()
  //         : this.renderConfirmationForm()}
  //     </div>
  //   );
  // }
  
  render() {
    return (
      <div className="Signup">
        <AutoAuthUnauthNavBar
           pathname={this.props.pathname}>
        </AutoAuthUnauthNavBar>
        <h2 className="text-center">Coming Soon!</h2>
      </div>
    );
  }
  
}
