import React, { Component } from "react";
import {
  HelpBlock,
  Tabs,
  Tab,
  TabContent,
  FormGroup,
  ControlLabel,
  FormControl,
  Glyphicon,
  ButtonGroup,
  Modal
} from "react-bootstrap";
import { Icon } from "semantic-ui-react";
import moment from 'moment';
import AutoAuthNavBar from "./AutoAuthNavBar";
import AutoAuthBreadcrumbs from "../components/AutoAuthBreadcrumbs";
import { navbarSetup,
         resetUserData } from "../libs/utils";
import {
         Button } from 'react-bootstrap';
import { getGroups, addGroup, updateGroupInfo } from "../libs/db-lib";
import LoaderButton from "../components/LoaderButton";
import ReactTable from 'react-table';
import '../stylesheets/ReactTable.css';
import 'react-table/react-table.css';

export default class ManageGroups extends Component {

  constructor(props) {
    super(props);

    this.state = {
      alertMessage: '',
      showModal: false,
      groupName: '',
      filter: '',
      showDisableModal: false,
      user: props.user,
      groupList: null,
      groupToDisable: '',
      groupNameToDisable: ''
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }
    try {
      await resetUserData(this);
      let groups = await getGroups();
      this.setState({
        groupList: groups ? groups : [],
        filteredList: groups ? groups : [],
      });

    } catch (e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
    this.setState({ windowWidth: window.outerWidth });
    window.addEventListener("resize", this.setWindowWidth.bind(this));
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  validateForm() {
    return this.state.groupName.length > 0;
  }

  activateSerialNumberVerify() {
    this.setState({ verifySerialNumber: true });
  }

  async handleConfirmModal() {

    // Handle disable group
    try {
      await updateGroupInfo(this.state.groupToDisable, this.state.groupNameToDisable, 'INACTIVE');
    } catch(e) {
      this.setState({
        showDisableModal: false,
        groupToDisable: '',
        groupNameToDisable: '',
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }

    let groups = this.state.groupList;
    let groupIndex = groups.findIndex((g) => {
      return g.groupID === this.state.groupToDisable;
    });
    if (groupIndex !== -1) {
      groups[groupIndex].groupState = 'INACTIVE';
    }

    this.setState({ showDisableModal: false,
                    groupToDisable: '',
                    groupNameToDisable: '',
                    groupList: groups });
  }

  handleCancelModal(e) {

    this.setState({ showDisableModal: false,
                    groupToDisable: '',
                    groupNameToDisable: '' });
  }

  handleDisableButton(groupId) {

    let group = this.state.groupList.find(group => group.groupID === groupId);
    let groupName = group.groupName;
    this.setState({ showDisableModal: true,
                    groupToDisable: groupId,
                    groupNameToDisable: groupName });
  }

  async handleEnableButton(groupId) {

    let group = this.state.groupList.find(group => group.groupID === groupId);
    let groupName = group.groupName;
    try {
      await updateGroupInfo(groupId, groupName, 'ACTIVE');
    } catch(e) {
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }

    let groups = this.state.groupList;
    let groupIndex = groups.findIndex((g) => {
      return g.groupID === groupId;
    });
    if (groupIndex !== -1) {
      groups[groupIndex].groupState = 'ACTIVE';
    }

    this.setState({ showDisableModal: false,
                    groupToDisable: '',
                    groupNameToDisable: '',
                    groupList: groups });
  }

  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }

  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }

  handleEditButton(groupId) {
    // Get the group info for this group
    let group = this.state.groupList.find(group => group.groupID === groupId);
    this.props.history.push({pathname: "/manageGroups/editGroup/", state: { group: group, tab: "editGroup"}});
  }

  handleManageUsersButton(groupId) {
    // Get the group info for this group
    let group = this.state.groupList.find(group => group.groupID === groupId);
    this.props.history.push({pathname: "/manageGroups/editGroup/", state: { group: group, tab: "users"}});
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    if (event.target.id === 'filter') {
      if (event.target.value.length > 0) {
        const filterArr = event.target.value ? event.target.value.split(' ').filter((f) => f !== '') : [];
        const filterStr = filterArr.reduce(((f1, f2) => f1 + '|' + f2) );
        const regexStr = new RegExp('(?:' + filterStr + ')', 'ig');
        const groupList = this.state.groupList.filter((g) => {
          return (
            regexStr.test(g.groupName)
          );
        });

        this.setState({
          filteredList: groupList ? groupList : [],
        });
      } else {
        this.setState({
          filteredList: this.state.groupList,
        });
      }
    }
  }

  handleCancel = () => {
    this.setState({ showModal: false });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let data = this.state;
    let results = await addGroup(data.groupName);
    if (results.error) {
      this.setState({
        alertMessage: results.error,
        showModal: true,
        isLoading: false
      });
    } else {
      this.props.history.push({ pathname: "/manageGroups/editGroup/",
                                state: { groupID: results.groupID, tab: "editGroup" }});
    }
  }

  render() {
    const navbarData = navbarSetup(this.state);

    const columnDefs = [];
    columnDefs.push(
      { Header: "Name",
        accessor: "name",
        minWidth: 200 }
    );
    columnDefs.push(
      { Header: "Validity Criteria",
        accessor: "validityCriteria",
        maxWidth: 150 }
    );
    columnDefs.push(
      { Header: "Validity Time",
        id: "validityParams",
        accessor: (g) => {
          if (g.validityCriteria === "TIME") {
            const start = moment(new Date(g.validityParams.startDate));
            const end = moment(new Date(g.validityParams.endDate));

            return (
              <div>
                <span className="text-bold">{start.format('YYYY-MM-DD HH:mm:ss')}</span>
                <span> to </span>
                <span className="text-bold">{end.format('YYYY-MM-DD HH:mm:ss') + ' UTC'}</span>
              </div>
            );
         } else {
            return "N/A";
          }
        },
        minWidth: 330 })
    if (this.state.windowWidth > 500) {
      columnDefs.push(
        { Header: "State",
          accessor: "state",
          className: "text-center",
          minWidth: 50 }
      );
    }
   columnDefs.push(
      { Header: (this.state.windowWidth > 500) ? "Actions" : "",
        id: "groupId",
        accessor: "groupId",
        minWidth: (this.state.windowWidth > 500)?90:50,
        maxWidth: 100,
        className: "text-center",
        Cell: (row) => {
          return (
            <div>
              <ButtonGroup>
                { this.state.user.userType === "ADMIN" ?
                  <Button bsStyle="link" href="#" onClick={this.handleEditButton.bind(this, row.original.groupID)}><Icon title="Edit Group" name="edit" /></Button>
                 : ""
                }
                <Button bsStyle="link" href="#" onClick={this.handleManageUsersButton.bind(this, row.original.groupID)}><Icon title="Manage Group Users" name="user" /></Button>
                { this.state.user.userType === "ADMIN" ?
                  <div>
                  { row.original.state === 'ACTIVE' ?
                  <Button bsStyle="link" href="#" onClick={this.handleDisableButton.bind(this, row.original.groupID)}><Icon title="Disable Group" name="times circle outline" /></Button>
                  :
                  <Button bsStyle="link" href="#" onClick={this.handleEnableButton.bind(this, row.original.groupID)}><Icon title="Enable Group" name="check circle outline" /></Button>
                  }
                  </div>
                : ""
                }
              </ButtonGroup>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      }
    );

    let groups = this.state && this.state.filteredList && this.state.filteredList.length > 0 ?
      this.state.filteredList.map((group) => {
        return {
          groupID: group.groupID,
          name: group.groupName,
          state: group.groupState,
          validityCriteria: group.validityCriteria,
          validityParams: group.validityParams,
        }
      })
      : [];

    // Temporary remove of ReactTable props until version 7
    /* eslint-disable react/forbid-foreign-prop-types */
    // @ts-ignore
    delete ReactTable.propTypes.TableComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TheadComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TbodyComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrGroupComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TrComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ThComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TdComponent;
    // @ts-ignore
    delete ReactTable.propTypes.TfootComponent;
    // @ts-ignore
    delete ReactTable.propTypes.FilterComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ExpanderComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotValueComponent;
    // @ts-ignore
    delete ReactTable.propTypes.AggregatedComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PivotComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PaginationComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PreviousComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NextComponent;
    // @ts-ignore
    delete ReactTable.propTypes.LoadingComponent;
    // @ts-ignore
    delete ReactTable.propTypes.NoDataComponent;
    // @ts-ignore
    delete ReactTable.propTypes.ResizerComponent;
    // @ts-ignore
    delete ReactTable.propTypes.PadRowComponent;
    /* eslint-enable react/forbid-foreign-prop-types */

    return (
      <div className="home">
        <AutoAuthNavBar
          manufacturer={navbarData.thisIsAManufacturer}
          serviceCenter={navbarData.thisIsAServiceCenter}
          name={navbarData.name}
          user={this.state.user ? this.state.user.user : this.state.user}
          shopRole={navbarData.shopRole}
          shopState={navbarData.shopState}
          emailVerified={navbarData.emailVerified}
          userHasAuthenticated={this.props.userHasAuthenticated}
          pathname={this.props.location.pathname}>
        </AutoAuthNavBar>
        <AutoAuthBreadcrumbs pathname={this.props.location.pathname} />
        <div className="panel-frame">

          <Tabs defaultActiveKey={1} id="tools-management-tabs">
            <Tab key="1" eventKey={1} title="Groups">
              <TabContent>
                { this.state.groupList ?
                <div className={this.state.windowWidth > 400 ? "col-lg-10 col-md-11" : "col-lg-10 col-md-11 no-left-padding"} >
                  <br />
                  <div className="auto-auth-table col-sm-12">
                    <FormGroup controlId="filter" bsSize="large">
                      <ControlLabel className={this.state.filterFieldActivate || this.state.filter.length > 0 ? "float-label field-active" : "float-label"}>Filter</ControlLabel>
                      <FormControl
                        maxLength="50"
                        type="text"
                        value={this.state.filter}
                        onChange={this.handleChange.bind(this)}
                        onFocus={this.activateField.bind(this)}
                        onBlur={this.disableField.bind(this)}
                      />
                    </FormGroup>
                    <ReactTable
                      columns={columnDefs}
                      data={groups}
                      className="-highlight"
                      defaultPageSize={10}
                      defaultSorted={[
                        {
                          id: "name",
                          desc: false
                        }
                      ]}
                    />
                  </div>
                </div>
                :
                <div className="loading-panel">
                  <Glyphicon glyph="repeat" className="spinning" />
                </div>
                }
              </TabContent>
            </Tab>
            { this.state.user && this.state.user.userType === "ADMIN" ?
            <Tab key="2" eventKey={2} title="Add Group">
              <TabContent>
                <form onSubmit={this.handleSubmit}>
                  <div className="col-sm-8">
                    <br />
                   <ControlLabel className="col-sm-4">Group Name</ControlLabel>
                   <FormGroup className="col-sm-8" controlId="groupName" bsSize="large">
                      <FormControl
                        autoFocus
                        className={this.state.verifyGroupName ? 'error' : ''}
                        type="text"
                        maxLength="100"
                        placeholder="Group Name"
                        value={this.state.groupName}
                        onChange={this.handleChange}
                      />
                      { this.state.verifyGroupName && this.state.groupName.length === 0 ?
                        <HelpBlock className="error">Group Name cannot be blank</HelpBlock>
                        : ''
                      }
                    </FormGroup>
                    <FormGroup>
                      <div className="col-sm-12">
                        <LoaderButton
                          block
                          className="btn-container red-button"
                          bsSize="large"
                          disabled={!this.validateForm()}
                          type="submit"
                          isLoading={this.state.isLoading}
                          text="Add Group"
                          loadingText="Adding group…"
                        />
                      </div>
                    </FormGroup>

                  </div>
                </form>
              </TabContent>
            </Tab>
            : ''
            }
          </Tabs>
        </div>

        <Modal show={this.state.showDisableModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Disable Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to disable the group: {this.state.groupNameToDisable}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleConfirmModal.bind(this, this.state.groupToDisable)}>Confirm</Button>
            <Button onClick={this.handleCancelModal.bind(this)}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
