import { systemInfo } from "../libs/utils";
import config from "../config";

const ADMIN_FUNCTION_CREATE_USERS           = 1;
const ADMIN_FUNCTION_UPDATE_USER_STATE      = 2;
const ADMIN_FUNCTION_UPDATE_USER_TYPE       = 3;
const ADMIN_FUNCTION_UPDATE_USER_ROLES      = 4;
const ADMIN_FUNCTION_CREATE_GROUP           = 5;
const ADMIN_FUNCTION_UPDATE_GROUP           = 6;
const ADMIN_FUNCTION_ADD_USER_TO_GROUP      = 7;
const ADMIN_FUNCTION_REMOVE_USER_FROM_GROUP = 8;
// const ADMIN_FUNCTION_CREATE_ROLE            = 9;
// const ADMIN_FUNCTION_UPDATE_ROLE            = 10;
const ADMIN_FUNCTION_ADD_ROLE_TO_GROUP      = 11;
const ADMIN_FUNCTION_REMOVE_ROLE_FROM_GROUP = 12;

const MGR_FUNCTION_ADD_USER_TO_GROUP         = 1;
const MGR_FUNCTION_REMOVE_USER_FROM_GROUP    = 2;

export async function engBridgeLogin(username, password) {
  return await fetch(config.apiGateway.APIURL + "/ebapi/login",
    { method: "post",
      body: JSON.stringify({
        username: username,
        password: password,
      })
    }
  ).then(result => {
    return result.json();
  }).then((response) => {
    if (response.errorDesc) {
      return response.errorDesc;
    } else {
      localStorage.setItem('Id-Token', response.IdToken);
      return null;
    }
  });
}

export async function getUserRecord() {
  const idToken = localStorage.getItem("Id-Token");
  return await fetch(config.apiGateway.URL + "/ebui/getUserInfo",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });
}

export async function updateUserState(userId, newState) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_USER_STATE,
    userID: userId,
    userState: newState,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }).then( result => {
      return result.json();
    }).then(response => {
      if (response.message) {
        return response.message;
      } else {
        return null;
      }
    });
}

function updateUserRole(userId, role) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_USER_ROLES,
    userID: userId,
    userRoleList: [role],
  });

  return fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }).then( result => {
      return result.json();
    }).then(response => {
      if (response.errorDesc) {
        return response.errorDesc;
      } else {
        return null;
      }
    });
}

function updateUserType(userId, type) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_USER_TYPE,
    userID: userId,
    userType: type,
  });

  return fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }).then( result => {
      return result.json();
    }).then(response => {
      if (response.errorDesc) {
        return { error: response.errorDesc };
      } else {
        return null;
      }
    });
}

export function getToolTypes() {
  const idToken = localStorage.getItem("Id-Token");
  return fetch(config.apiGateway.URL + "/ebui/getManufacturerList",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });

}

export async function getSystemConfig() {
  const idToken = localStorage.getItem("Id-Token");
  return await fetch(config.apiGateway.URL + "/ebui/getUIConfigs",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
     }
  ).then(result => { return result.json(); }
  ).then((response) => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  }).catch((err) => {
    return err;
  });
}

export async function createUser(username, password, firstName, lastName, email, recaptcha, inviteCode) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    userName: username,
    password: password,
    firstName: firstName,
    lastName: lastName,
    email: email,
    recaptcha: recaptcha,
    invitationCode: inviteCode
  });
  return await fetch(config.apiGateway.URL + "/ebui/createUser",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then((response) => {
    if (response.message) {
      return response.message;
    } else {
      return null;
    }
  }).catch((err) => {
    return err;
  });
}

export async function updateUser(userID, authProvider, firstName, lastName, email, type, role) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    userID: userID,
    firstName: firstName,
    lastName: lastName,
    email: email,
  });
  if (authProvider === "COGNITO") {
    let response = await fetch(config.apiGateway.URL + "/ebui/updateUserInfo",
      { method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': bodyStr.length,
          'Authorization': idToken
        },
        body: bodyStr,
      }
    ).then(result => {
      return result.json();
    }).then(response => {
      if (response.message) {
        return {error: response.message};
      } else {
        return response;
      }
    });
    if (response.message) {
      return { error: response.message };
    }
  }
  if (type) {
    let response = await updateUserType(userID, type);
    if (response && response.error) {
        return { error: response.error };
    }
  }

  if (role) {
    let response = updateUserRole(userID, role);
    if (response && response.error) {
      return { error: response.error };
    } else {
      return response;
    }
  }
}

export function getTools() {
  const idToken = localStorage.getItem("Id-Token");
  return fetch(config.apiGateway.URL + "/ebui/getRegisteredToolList",
   { method: "post",
     headers: {
       'Content-Type': 'application/json',
       'Content-Length': 0,
       'Authorization': idToken
     },
   }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });
}

export function addTool(serialNum, manufacturer, model) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    toolSerial: serialNum,
    toolManufacturerUUID: manufacturer,
    toolModelUUID: model
  });
  return fetch(config.apiGateway.URL + "/ebui/registerTool",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.errorDesc) {
      return {error: response.errorDesc};
    } else {
      return response;
    }
  });
}

export function addGroup(groupName) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_CREATE_GROUP,
    groupName: groupName
  });
  return fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });
}

export function removeTool(toolId) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    toolID: toolId
  });
  return fetch(config.apiGateway.URL + "/ebui/removeTool",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return null;
    }
  });
}


export async function findUser(username) {
  const idToken = localStorage.getItem("Id-Token");
  const userRec = await fetch(config.apiGateway.URL + "/ebui/findUser?userName="+username,
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => { return result.json(); }
  ).then((response) => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  }).catch((err) => {
    return err;
  });

  return ({firstName: userRec.firstName,
           lastName: userRec.lastName,
           userId: userRec.userID,
           username: username});
}

export async function getUsers() {
  const idToken = localStorage.getItem("Id-Token");
  let res = await fetch(config.apiGateway.URL + "/ebui/getUserList",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => { return result.json(); }
  ).then(response => {
    return response.userList;
  }).catch(error => {
    console.log("getUsers error: ", JSON.stringify(error));
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (res.error) {
    console.log("Error trying to record user session: ", res.error);
  }
  return res;
}

export async function recordUserSession() {
  const idToken = localStorage.getItem("Id-Token");
  const sysInfo = systemInfo();
  const bodyStr = JSON.stringify({
    browserName: sysInfo.browserName,
    browserVersion: sysInfo.browserVersion,
    hostOS: sysInfo.hostOS
  });

  return await fetch(config.apiGateway.URL + "/ebui/recordUserSession",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return null;
    }
  });
}

export async function getGroups() {
  const idToken = localStorage.getItem("Id-Token");

  return await fetch(config.apiGateway.URL + "/ebui/getGroupList",
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
    }
  ).then(result => { return result.json(); }
  ).then(result => {
    return result.groupList;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getGroupInfo(groupID) {
  const idToken = localStorage.getItem("Id-Token");
  return await fetch(config.apiGateway.URL + "/ebui/getGroupInfo?groupID="+groupID,
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
    }
  ).then(result => { return result.json(); }
  ).then(result => {
    return result.groupInfo;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function getUserGroups(userID) {
  const idToken = localStorage.getItem("Id-Token");
  let path = "/ebui/getUserGroups";
  if (userID) {
    path += "?userID="+userID;
  }
  return await fetch(config.apiGateway.URL + path,
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => {
    return result.json();
  }).then(response => {
    if (response.message) {
      return {error: response.message};
    } else {
      return response;
    }
  });
}

export async function getRoles() {
  const idToken = localStorage.getItem("Id-Token");
  let res = await fetch(config.apiGateway.URL + "/ebui/getRoleList",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      }
    }
  ).then(result => { return result.json(); }
  ).then(result => {
    return result.roleList;
  }).catch(error => {
    console.log("getRoles error: ", JSON.stringify(error));
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (res.error) {
    console.log("Error trying to get group roles list: ", res.error);
  }
  return res;
}

export async function getGroupRoles(groupId) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    groupID: groupId
  });
  let res = await fetch(config.apiGateway.URL + "/ebui/getGroupRoleList",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then(result => { return result.json(); }
  ).then(result => {
    return result.roleList;
  }).catch(error => {
    console.log("getGroupRoles error: ", JSON.stringify(error));
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (res.error) {
    console.log("Error trying to get roles list: ", res.error);
  }
  return res;
}

export async function getGroupUsers(groupId) {
  const idToken = localStorage.getItem("Id-Token");
  let res = await fetch(config.apiGateway.URL + "/ebui/getGroupUsers?groupID="+groupId,
    { method: "get",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': 0,
        'Authorization': idToken
      },
    }
  ).then(result => { return result.json(); }
  ).then(result => {
    return result.userList;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (res.error) {
    console.log("Error trying to get group users list: ", res.error);
  }
  return res;
}

export async function updateGroupInfo(groupId, groupName, groupState, validityCriteria, validityParams) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_UPDATE_GROUP,
    groupID: groupId,
    groupName: groupName,
    groupState: groupState
  });
  let response = await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });

  if (validityCriteria) {
    const validParams = {
      startDate: new Date(validityParams.startDate).toString(),
      endDate: new Date(validityParams.endDate).toString(),
    }
    const validStr = validityCriteria === "TIME" ?
      JSON.stringify({
        groupID: groupId,
        validityCriteria: validityCriteria,
        validityParams: validParams
      })
      :
      JSON.stringify({
        groupID: groupId,
        validityCriteria: validityCriteria,
      });
    response = await fetch(config.apiGateway.URL + "/ebui/setGroupValidity",
      { method: "post",
        headers: {
          'Content-Type': 'application/json',
          'Content-Length': validStr.length,
          'Authorization': idToken
        },
        body: validStr
      }
    ).then((result) => { return result.json() }
    ).then( result => {
      if (result.errorDesc) {
        return {error: result.errorDesc};
      } else {
        return result;
      }
    }).catch(error => {
      if (error.message) {
        return {error: error.message};
      } else {
        return {error: error};
      }
    });
  }
  return response;
}

export async function setGroupActiveRole(groupId, activeRole) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    groupID: groupId,
    roleID: activeRole === '' ? "0" : activeRole,
  });
  return await fetch(config.apiGateway.URL + "/ebui/setGroupActiveRole",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
      return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function removeRoleFromGroup(groupId, role) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_REMOVE_ROLE_FROM_GROUP,
    groupID: groupId,
    roleID: role,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addRoleToGroup(groupId, role) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_ADD_ROLE_TO_GROUP,
    groupID: groupId,
    roleID: role,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function removeUserFromGroup(requestorType, groupId, user) {
  const idToken = localStorage.getItem("Id-Token");
  const url = config.apiGateway.URL + (requestorType === "ADMIN" ? "/ebui/execAdminFunction" : "/ebui/execMgrFunction");
  let bodyObj = {
    groupID: groupId,
    userID: user,
  };
  if (requestorType === "ADMIN") {
    bodyObj.adminFunctionID = ADMIN_FUNCTION_REMOVE_USER_FROM_GROUP;
  } else {
    bodyObj.mgrFunctionID = MGR_FUNCTION_REMOVE_USER_FROM_GROUP;
  }
  const bodyStr = JSON.stringify(bodyObj);
  return await fetch(url,
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addUserToGroup(requestorType, groupId, user) {
  const idToken = localStorage.getItem("Id-Token");
  const url = config.apiGateway.URL + (requestorType === "ADMIN" ? "/ebui/execAdminFunction" : "/ebui/execMgrFunction");
  let bodyObj = {
    groupID: groupId,
    userID: user,
  };
  if (requestorType === "ADMIN") {
    bodyObj.adminFunctionID = ADMIN_FUNCTION_ADD_USER_TO_GROUP;
  } else {
    bodyObj.mgrFunctionID = MGR_FUNCTION_ADD_USER_TO_GROUP;
  }
  const bodyStr = JSON.stringify(bodyObj);
  return await fetch(url,
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr,
    }
  ).then((result) => { return result.json() }
  ).then( result => {
      return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}

export async function addUsers(users) {
  const idToken = localStorage.getItem("Id-Token");
  const bodyStr = JSON.stringify({
    adminFunctionID: ADMIN_FUNCTION_CREATE_USERS,
    userList: users,
  });
  return await fetch(config.apiGateway.URL + "/ebui/execAdminFunction",
    { method: "post",
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': bodyStr.length,
        'Authorization': idToken
      },
      body: bodyStr
    }
  ).then((result) => { return result.json() }
  ).then( result => {
    return result;
  }).catch(error => {
    if (error.message) {
      return {error: error.message};
    } else {
      return {error: error};
    }
  });
}
