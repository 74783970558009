import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Support.css";
import {
  Row,
} from "react-bootstrap";

export default class FAQ extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      user: props.user,
      isLoading: true,
    };
  }
  
  render() {

    return (
      <div>
        <div key="menubar" className="home">
          <Navbar.Header>
            <Navbar.Brand>
              <NavLink to="/">
                <img className="brand-image" alt="AutoAuth" src='img/AutoAuth-transparent-TM.png' />
              </NavLink>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>

          <Row key="welcome" className="panel welcome">
            <div>FAQ - Coming Soon</div>
          </Row>
          
        </div>
      </div>
    );
  }
}