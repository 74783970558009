import React, { Component } from "react";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { passwordValidate, 
         usernameValidate, 
         nameValidate, 
         isValidEmail,
         fieldChangeCase } from "../libs/utils";
import { createUser, getSystemConfig } from "../libs/db-lib";
import AlertModal from "../components/AlertModal";
import "../stylesheets/App.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      confirmationCode: "",
      inviteCode: "",
      newUser: null,
      verifyFirstName: false,
      verifyLastName: false,
      verifyEmail: false,
      verifyInviteCode: false,
      disclaimerAccepted: false,
      config: {}
    };
  }
  
  async componentDidMount() {
    let config = await getSystemConfig();
    this.setState({config: config});
  }

  validateForm() {
    let passwordValid = passwordValidate(this.state.password);
    let usernameValid = usernameValidate(this.state.username);
    let namesValid = nameValidate(this.state.firstName) && nameValidate(this.state.lastName);
    let emailValid = isValidEmail(this.state.email);

    return (
      this.state.username.length > 0 &&
      this.state.email.length > 0 &&
      this.state.firstName.length > 0 &&
      this.state.lastName.length > 0 &&
      (this.props.inviteCode !== "1" || this.state.inviteCode.length > 0) &&
      passwordValid &&
      usernameValid &&
      namesValid &&
      emailValid &&
      this.state.password === this.state.confirmPassword
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }
  
  activateFirstNameVerify() {
    this.setState({ verifyFirstName: true });
  }

  activateLastNameVerify() {
    this.setState({ verifyLastName: true });
  }

  activateInviteCodeVerify() {
    this.setState({ verifyInviteCode: true });
  }
  
  activateEmailVerify() {
    this.setState({ verifyEmail: true });
  }

  onloadCallback(response) {
    return;
  }
  
  handleCancel = () => {
    this.setState({ showModal: false });
  }
  
  handleChange = event => {
    if (event.target.id === 'username' || 
        event.target.id === 'email') {
      fieldChangeCase(this, event.target, "lower");
    } else {
      this.setState({
        [event.target.id]: event.target.value
      });
    }
    if (event.target.id === 'email') {
      this.activateEmailVerify();
    }
    if (event.target.id === 'firstName') {
      this.activateFirstNameVerify();
    }
    if (event.target.id === 'lastName') {
      this.activateLastNameVerify();
    }
    if (event.target.id === 'inviteCode') {
      this.activateInviteCodeVerify();
    }
  }
  
  activateField = event => {
    this.setState({
      [event.target.id+"FieldActivate"]: true
    });
  }
  
  disableField = event => {
    if (event.target.value === "") {
      this.setState({
        [event.target.id+"FieldActivate"]: false
      });
    }
  }
  
  handleAcceptDisclaimer = event => {
    if (this.state.disclaimerAccepted) {
      this.setState({ disclaimerAccepted: false }); 
    } else {
      this.setState({ disclaimerAccepted: true }); 
    }
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let err = await createUser(
                            this.state.username,
                            this.state.password,
                            this.state.firstName,
                            this.state.lastName,
                            this.state.email,
                            this.state.inviteCode);
    if (err) {
      this.setState({
        alertMessage: err,
        showModal: true,
        isLoading: false
      });
    } else {
      this.setState({
        newUser: "created"
      });
    }
 
    this.setState({ isLoading: false });
  }
  
  renderConfirmationForm() {
    return (
      <div className="Login col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 white-container">
        <div className="text-center">Account Validation link has been sent to: {this.state.email}</div>
        <div className="text-center">After validating your account you can log in by clicking the Login button on the top right of the page</div>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <div className="Login col-md-offset-4 col-md-4 col-sm-offset-2 col-sm-8 white-container">
          <form onSubmit={this.handleSubmit}>
            <FormGroup>
              <h2 className="text-center">Request Account</h2>
            </FormGroup>
            <FormGroup controlId="username" bsSize="large">
              <ControlLabel className={this.state.usernameFieldActivate || this.state.username.length > 0 ? "float-label field-active" : "float-label"}>Username</ControlLabel>
              <FormControl
                className={this.state.username.length > 0 && !usernameValidate(this.state.username)?'error':''}
                maxLength="50"
                type="text"
                value={this.state.username}
                onChange={this.handleChange}
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              { this.state.username.length > 0 && !usernameValidate(this.state.username) ?
                <HelpBlock className="error">Username must be at least 8 alphanumeric lowercase characters starting with a letter</HelpBlock>
                : ''
              }
            </FormGroup>
            <FormGroup controlId="firstName" bsSize="large">
              <ControlLabel className={this.state.firstNameFieldActivate || this.state.firstName.length > 0 ? "float-label field-active" : "float-label"}>First Name</ControlLabel>
              <FormControl
                className={this.state.verifyFirstName && !nameValidate(this.state.firstName)?'error':''}
                maxLength="50"
                type="text"
                value={this.state.firstName}
                onChange={this.handleChange}
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              { this.state.verifyFirstName && !nameValidate(this.state.firstName) ?
                <HelpBlock className="error">First name cannot be blank and cannot contain special characters</HelpBlock>
                : ''
              }
            </FormGroup>
            <FormGroup controlId="lastName" bsSize="large">
              <ControlLabel className={this.state.lastNameFieldActivate || this.state.lastName.length > 0 ? "float-label field-active" : "float-label"}>Last Name</ControlLabel>
              <FormControl
                className={this.state.verifyLastName && !nameValidate(this.state.lastName)?'error':''}
                maxLength="50"
                type="text"
                value={this.state.lastName}
                onChange={this.handleChange}
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
             />
              { this.state.verifyLastName && !nameValidate(this.state.lastName) ?
                <HelpBlock className="error">Last name cannot be blank and cannot contain special characters</HelpBlock>
                : ''
              }
            </FormGroup>
            <FormGroup controlId="email" bsSize="large">
              <ControlLabel className={this.state.emailFieldActivate || this.state.email.length > 0 ? "float-label field-active" : "float-label"}>Email</ControlLabel>
              <FormControl
                className={this.state.verifyEmail && !isValidEmail(this.state.email)?'error':''}
                maxLength="100"
                type="text"
                value={this.state.email}
                onChange={this.handleChange}
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              {this.state.verifyEmail && !isValidEmail(this.state.email) ?
                <HelpBlock className="error">Enter a valid email address</HelpBlock>
                : ''
              }
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <ControlLabel className={this.state.passwordFieldActivate || this.state.password.length > 0 ? "float-label field-active" : "float-label"}>Password</ControlLabel>
              <FormControl
                className={this.state.password.length > 0 && !passwordValidate(this.state.password) ?'error':''}
                maxLength="50"
                value={this.state.password}
                onChange={this.handleChange}
                type="password"
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              { this.state.password.length > 0 && !passwordValidate(this.state.password) ?
                <HelpBlock className="error">Password must be at least 8 characters with at least one number, one special character, one uppercase letter and one lowercase letter</HelpBlock>
                : ''
              }
            </FormGroup>
            <FormGroup controlId="confirmPassword" bsSize="large">
              <ControlLabel className={this.state.confirmPasswordFieldActivate || this.state.confirmPassword.length > 0 ? "float-label field-active" : "float-label"}>Confirm Password</ControlLabel>
              <FormControl
                className={this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword?'error':''}
                maxLength="50"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                type="password"
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              { this.state.confirmPassword.length > 0 && this.state.password !== this.state.confirmPassword ?
                <HelpBlock className="error">Password and Confirm Password do not match</HelpBlock>
                : ''
              }
            </FormGroup>
            { this.props.inviteCode === "1" ?
            <FormGroup controlId="inviteCode" bsSize="large">
              <ControlLabel className={this.state.inviteCodeFieldActivate || this.state.inviteCode.length > 0 ? "float-label field-active" : "float-label"}>Invite Code</ControlLabel>
              <FormControl
                className={this.state.inviteCode && this.state.inviteCode.length === 0?'error':''}
                maxLength="50"
                type="text"
                value={this.state.inviteCode}
                onChange={this.handleChange}
                onFocus={this.activateField.bind(this)}
                onBlur={this.disableField.bind(this)}
              />
              {this.state.verifyInviteCode && this.state.inviteCode.length === 0 ?
                <HelpBlock className="error">Invite Code cannot be blank</HelpBlock>
                : ''
              }
            </FormGroup>
            : '' }
            {/*<FormGroup controlId="termsAndConditions" bsSize="large">*/}
            {/*  <div>*/}
            {/*    <a href="/ISS-AutoAuth-Subscriber-Agreement.pdf" download>Terms & Conditions</a>*/}
            {/*  </div>*/}
            {/*</FormGroup>*/}
            {/*<FormGroup controlId="acceptDisclaimer" bsSize="large">*/}
            {/*  <div>*/}
            {/*    <input*/}
            {/*      id="acceptDisclaimer"*/}
            {/*      type="checkbox"*/}
            {/*      checked={!!this.state.disclaimerAccepted}*/}
            {/*      onChange={this.handleAcceptDisclaimer}*/}
            {/*    />*/}
            {/*    <span className="left-padding">Accept Terms & Conditions</span>*/}
            {/*  </div>*/}
            {/*</FormGroup>*/}
            <div className="btn-container">
              <LoaderButton
                id="submit-button"
                className="red-button"
                block
                bsSize="large"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text="Request Account"
                loadingText="Requesting…"
              />
            </div>
          </form>
        </div>
        <div className="Login col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8 white-container">
          <div className="text-center infoLinks">
            <a href="https://info.autoauth.com" target="_blank" rel="noopener noreferrer">
              About Us
            </a>
            <span className="spacer10">|</span>
            <a href="https://info.autoauth.com/contact" target="_blank" rel="noopener noreferrer">
              Contact Us
            </a>
            <span className="spacer10">|</span>
            <a href="https://info.autoauth.com/contact/#faq_section" target="_blank" rel="noopener noreferrer">
              FAQ
            </a>
          </div>
          <div className="text-center infoLinks">
            <a href="https://info.autoauth.com/contact/privacy-policy" target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            <span className="spacer10">|</span>
            <a href="https://info.autoauth.com/contact/cookies-policy" target="_blank" rel="noopener noreferrer">
              Cookies Policy
            </a>
            <span className="spacer10">|</span>
            <a href="https://info.autoauth.com/contact/refund-cancellation-policy" target="_blank" rel="noopener noreferrer">
              Refund/Cancelation Policy
            </a>
         </div>
        </div>
        <AlertModal message={this.state.alertMessage} showModal={this.state.showModal} size="small" handleCancel={this.handleCancel.bind(this)}></AlertModal>
      </div>
    );
  }

  render() {
    return (
      <div className="Signup">
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
