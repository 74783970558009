import React, { Component } from 'react';
import { 
         Modal,
         Button
       } from 'react-bootstrap';

// cell renderer for the actions column. this is a very basic cell renderer,

export default class AlertModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      message: ''
    };
  }
  
  componentDidMount() {
    this.setState({
      showModal: this.props.showModal,
      message: this.props.message
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.message !== this.props.message ||
        nextProps.showModal !== this.props.showModal ||
        nextProps.size !== this.props.size) {
      this.setState({
        message: nextProps.message,
        showModal: nextProps.showModal
      });
      return true;
    } else if (nextState !== this.state) {
      return true;
    } else {
      return false;
    }
  }
  
  handleCancelModal(e) {
    if (e)
      e.preventDefault();
    
    this.setState({ showModal: false });
    if (this.props.handleCancel) {
      this.props.handleCancel();
    }
  }
  
  render() {
  
    const msg = this.props.message;
    const message = msg?(typeof msg === 'object')?msg.message:msg:'';
    
    
    return (
      <div>
        <Modal bsSize={this.props.size?this.props.size:""} show={this.props.showModal} onHide={this.handleCancelModal.bind(this)}>
          <Modal.Body>
            <p>{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleCancelModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}