import React, { Component } from "react";
import AutoAuthNavBar from "./AutoAuthNavBar";
import { navbarSetup, resetUserData } from "../libs/utils";
import "./Support.css";
import {
  Glyphicon,
  Row,
} from "react-bootstrap";



export default class UserFAQ extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      user: props.user,
      isLoading: true
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) { 
      return;
    }
    try {
      await resetUserData(this, true);
      if (this.state.dataRetrieveError) {
        this.setState({
          alertMessage: this.state.dataRetrieveError,
          showModal: true,
          isLoading: false
        });
      } else {
        this.props.updateUserInfo(this.state.user);
        this.setState({isLoading: false});
      }
    } catch (e) {
      console.log("Error getting User Info: ", e);
      this.setState({
        alertMessage: e.message,
        showModal: true,
        isLoading: false
      });
    }
  }

  renderLander() {

    const navbarData = navbarSetup(this.state);
    
    let shops = this.state && this.state.user && this.state.user.shops ?
      this.state.user.shops.map((shop) => {
        return {
          shopID: shop.shopID,
          name: shop.shopName,
          role: shop.shopUserRole,
          state: shop.shopUserState
        }
      })
      : [];
    
    return (
      <div>
        { this.state && this.state.user ?
        <div key="menubar" className="home">
          <AutoAuthNavBar 
            manufacturer={navbarData.thisIsAManufacturer} 
            serviceCenter={navbarData.thisIsAServiceCenter} 
            name={navbarData.name} 
            shop={navbarData.shop} 
            shops={shops}
            shopRole={navbarData.shopRole} 
            shopState={navbarData.shopState} 
            emailVerified={navbarData.emailVerified} 
            userHasAuthenticated={this.props.userHasAuthenticated}
            pathname={this.props.location.pathname}>
          </AutoAuthNavBar>
          <Row key="welcome" className="panel welcome">
            <div>FAQ - Coming Soon</div>
          </Row>
          
        </div>
        : this.state.dataRetrieveError ?
        null 
        :
          <div className="loading-panel">
            <Glyphicon glyph="repeat" className="spinning" />
          </div>
        }
      </div>
    );
  }

  renderNotAuthenticated() {

    return (
      <div className="home">
        <div className="echo-test">
          There was a problem authenticating
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderLander(): ''}
      </div>
    );
  }
}
